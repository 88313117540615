import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";
import "ag-grid-autocomplete-editor/dist/main.css";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";

import ProductTypes from "../ProductTypes";
import ScopeTypes from "../ScopeTypes";
import ConsumptionTaxRates from "../ConsumptionTaxRates";
import ProductRecordTypes from "../ProductRecordTypes";
import CafereoProductStatus from "../productStatus/CafereoProductStatus";
import MakerProductStatus from "../productStatus/MakerProductStatus";
import ProductRanks from "../ProductRanks";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import moment from "moment";

const stockStatuses = { true: "✔", false: "　" };
const CafereoEditable = [
  {
    headerName: "登録日", // 1
    field: "createDate",
    filter: "agDateColumnFilter",
    type: "dpDateColumn",
  },
  {
    headerName: "商品ステータス", // 2
    colId: "productStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: CafereoProductStatus.all() },
    valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
  },
  {
    headerName: "商品区分", // 3
    field: "productType",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorPopup: true,
    filter: "dpSelectionFilter",
    filterParams: { options: ProductTypes.all() },
    cellEditorParams: {
      values: Object.keys(ProductTypes.refDataArray()),
      cellEditorPopup: true,
    },
    refData: ProductTypes.refDataArray(),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateProductType) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "仕入先略称", // 4
    field: "supplierShortName",
  },
  { headerName: "JANCODE", field: "jancode" }, // 5
  {
    headerName: "商品ランク", // 6
    field: "productRank",
    filter: "dpSelectionFilter",
    filterParams: { options: ProductRanks.all() },
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      values: ProductRanks.all().map((productRank) => {
        return productRank.value;
      }),
    },
    refData: ProductRanks.refDataArray(),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateProductRank) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "タイトル", // 7
    field: "title",
    editable: true,
    cellEditor: AutocompleteSelectCellEditor,
    valueFormatter: (params) => {
      if (params.value) {
        return params.value.label;
      }
      return "";
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const valueALbel = valueA.value ? valueA.label : nodeA.data.title;
      const valueBLbel = valueB.value ? valueB.label : nodeB.data.title;
      if (valueALbel == valueBLbel) return 0;
      return valueALbel > valueBLbel ? 1 : -1;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateTitle) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "商品名", field: "productName" }, // 8
  {
    headerName: "上代", // 9
    field: "retailPrice",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateRetailPrice) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "仕入価格", // 10
    field: "purchasePrice",
    type: "dpNumericColumn",
  },
  {
    headerName: "仕入掛率", // 11
    field: "purchaseRate",
    type: "dpPercentColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updatePurchaseRate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "消費税", // 12
    field: "consumptionTaxRate",
    type: "dpPercentColumn",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ConsumptionTaxRates.all().map((ConsumptionTaxRate) => {
        return ConsumptionTaxRate.value;
      }),
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateConsumptionTaxRate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "商品説明", // 13
    field: "productDetail",
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 10000,
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateProductDetail) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "カフェレオ締日", field: "cafereoClosingDate", filter: "agDateColumnFilter", type: "dpDateColumn" }, // 14
  {
    headerName: "発注締日", // 15
    field: "orderClosingDate",
    type: "dpEditableCustomDateColumn",
    cellEditorFramework: "CustomDateCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateOrderClosingDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
    valueSetter: (params) => {
      params.data.orderClosingDate = params.newValue;
      params.data.cafereoClosingDate = moment(params.newValue, "YYYY-MM-DD").subtract(2, "days");
    },
  },
  {
    headerName: "発売日", // 16
    field: "releaseDate",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateReleaseDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "受注単位", // 17
    field: "orderUnit",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateOrderUnit) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "発注単位", // 18
    field: "unit",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateUnit) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "情報解禁日", // 19
    field: "banDatetime",
    type: "dpEditableColumn",
    cellEditorFramework: "DateTimeCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD HH:mm"),
    filterValueGetter: (params) => {
      const date = moment(new Date(params.data.banDatetime));
      return date.isValid() ? date.format("YYYY/MM/DD HH:mm") : null;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateBanDatetime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "発売元", // 20
    field: "salesAgencyName",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateSalesAgencyName) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "販売元", // 21
    field: "salesOriginName",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateSalesOriginName) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "カテゴリー", // 22
    field: "categoryId",
    editable: true,
    cellEditor: AutocompleteSelectCellEditor,
    filterValueGetter: (params) => {
      return params.data.categoryId.value ? String(params.data.categoryId.label) : String(params.data.categoryName);
    },
    valueFormatter: (params) => {
      if (params.value.label) {
        return params.value.label;
      } else {
        return params.data.categoryName;
      }
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const valueALbel = valueA.value ? valueA.label : nodeA.data.categoryName;
      const valueBLbel = valueB.value ? valueB.label : nodeB.data.categoryName;
      if (valueALbel == valueBLbel) return 0;
      return valueALbel > valueBLbel ? 1 : -1;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateCategoryId) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "食品", // 23
    field: "confectionery",
    type: "dpCheckmarkColumn",
  },
  { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" }, // 24
  { headerName: "仕入担当者", field: "supplierChargeName" }, // 25
  {
    headerName: "PCS", // 26
    field: "pcs",
    type: "dpCheckmarkColumn",
  },
  { headerName: "PCS JAN", field: "jancodePcs" }, // 27
  {
    headerName: "再販情報", // 28
    colId: "recordType",
    filter: "dpSelectionFilter",
    filterParams: { options: ProductRecordTypes.all() },
    valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
  },
  { headerName: "ASIN", field: "asin" }, // 29
  // 詳細用ダミー項目
  {
    headerName: "商品CD", // 30
    field: "productCd",
    hide: true,
  },
  {
    headerName: "更新日", // 31
    field: "updateDate",
    hide: true,
  },
  {
    headerName: "Box入数", // 32
    field: "inBoxQuantity",
    type: "dpNumericColumn",
  },
  {
    headerName: "上限数", // 33
    field: "maximumQuantity",
    hide: true,
  },
  {
    headerName: "初回締後追加不可", // 34
    field: "nonAdded",
    hide: true,
  },
  { headerName: "付属商品CD", field: "accessoriesJanCode", hide: true }, // 35
  {
    headerName: "CT中Box入数", // 36
    field: "inCtBoxQuantity",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateInCtBoxQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "CT中pcs入数", // 37
    field: "inCtPcsQuantity",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateInCtPcsQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "パッケージサイズ", field: "packageSize", hide: true }, // 38
  {
    headerName: "CTsizeW", // 39
    field: "ctSizeWidth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeD", // 40
    field: "ctSizeDepth",
    type: "dpNumericColumn",
  },
  {
    headerName: "CTsizeH", // 41
    field: "ctSizeHeight",
    type: "dpNumericColumn",
  },
  {
    headerName: "PKGsizeW(cm)", // 42
    field: "pkgSizeWidth",
    hide: true,
  },
  {
    headerName: "PKGsizeD(cm)", // 43
    field: "pkgSizeDepth",
    hide: true,
  },
  {
    headerName: "PKGsizeH(cm)", // 44
    field: "pkgSizeHeight",
    hide: true,
  },
  { headerName: "賞味期限", field: "sellBy", hide: true }, // 45
  { headerName: "原産国", field: "countryOfOrigin" }, // 46
  { headerName: "飛行機検査不可", field: "airplaneInspection", hide: true }, // 47
  { headerName: "危険物", field: "hazardousMaterial", hide: true }, // 48
  { headerName: "原材料", field: "rawMaterials", hide: true }, // 49
  { headerName: "コピーライト", field: "copyright", width: 150 }, // 50
  { headerName: "仕入担当者", field: "createUser", hide: true }, // 51
  { headerName: "商品名カナ", field: "productNameKana", hide: true }, // 52
  { headerName: "メーカー備考", field: "makerRemarks", hide: true }, // 53
  {
    headerName: "Cafereo備考", // 54
    field: "cafereoRemarks",
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 10000,
    },
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateCafereoRemarks) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "販売店公開範囲", // 55
    field: "scopeType",
    filter: "dpSelectionFilter",
    filterParams: { options: ScopeTypes.all() },
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      values: Object.keys(ScopeTypes.refDataArray()),
      cellEditorPopup: true,
    },
    refData: ScopeTypes.refDataArray(),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateScopeType) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "公開販売店", // 56
    field: "scopedCustomers",
    editable: true,
    valueFormatter: (params) => {
      if (Array.isArray(params.value)) {
        params.data.scopedCustomers = params.data.scopedCustomers.join(":");
        return params.value.join(":");
      } else {
        return params.value;
      }
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateScopedCustomers) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "メーカー在庫あり", // 57
    field: "stockStatus",
    filter: "dpBooleanFilter",
    filterParams: { caption: stockStatuses },
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      values: [true, false],
      cellEditorPopup: true,
    },
    refData: stockStatuses,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateStockStatus) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 商品名", // 58
    headerClass: "resale-grid-header-class",
    field: "resaleProductName",
  },
  {
    headerName: "再販前 商品説明", // 59
    headerClass: "resale-grid-header-class",
    field: "resaleProductDetail",
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 10000,
    },
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleProductDetail) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 上代", // 60
    headerClass: "resale-grid-header-class",
    field: "resaleRetailPrice",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleRetailPrice) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 仕入価格", // 61
    headerClass: "resale-grid-header-class",
    field: "resalePurchasePrice",
    type: "dpNumericColumn",
  },
  {
    headerName: "再販前 仕入掛率", // 62
    headerClass: "resale-grid-header-class",
    field: "resalePurchaseRate",
    type: "dpPercentColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResalePurchaseRate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 発注締日", // 63
    headerClass: "resale-grid-header-class",
    field: "resaleOrderClosingDate",
    type: "dpEditableCustomDateColumn",
    cellEditorFramework: "CustomDateCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleOrderClosingDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
    valueSetter: (params) => {
      params.data.resaleOrderClosingDate = params.newValue;
    },
  },
  {
    headerName: "再販前 発売日", // 64
    headerClass: "resale-grid-header-class",
    field: "resaleReleaseDate",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleReleaseDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 受注単位", // 65
    headerClass: "resale-grid-header-class",
    field: "resaleOrderUnit",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleOrderUnit) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 発注単位", // 66
    headerClass: "resale-grid-header-class",
    field: "resaleUnit",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleUnit) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "再販前 情報解禁日", // 67
    headerClass: "resale-grid-header-class",
    field: "resaleBanDatetime",
    type: "dpEditableColumn",
    cellEditorFramework: "DateTimeCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD HH:mm"),
    filterValueGetter: (params) => {
      const date = moment(new Date(params.data.resaleBanDatetime));
      return date.isValid() ? date.format("YYYY/MM/DD HH:mm") : null;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateResaleBanDatetime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
];
const SupplierEditable = [
  {
    headerName: "商品ステータス",
    colId: "productStatus",
    filter: "dpSelectionFilter",
    filterParams: { options: MakerProductStatus.all() },
    valueGetter: (params) => MakerProductStatus.of(params.data.productStatus),
  },
  {
    headerName: "区分",
    colId: "recordType",
    filterParams: { options: ProductRecordTypes.all() },
    valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
  },
  { headerName: "在庫あり", field: "stockStatus", type: "dpCheckmarkColumn" },
  {
    headerName: "登録日",
    field: "createDate",
    filter: "agDateColumnFilter",
    type: "dpDateColumn",
  },
  {
    headerName: "更新日",
    field: "updateDate",
    filter: "agDateColumnFilter",
    type: "dpDateColumn",
  },
  {
    headerName: "発売日",
    field: "releaseDate",
    type: "dpEditableColumn",
    cellEditorFramework: "AboutDateCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateReleaseDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "発注締日",
    field: "orderClosingDate",
    filter: "agDateColumnFilter",
    type: "dpEditableCustomDateColumn",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateOrderClosingDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "情報解禁日時",
    field: "banDatetime",
    type: "dpEditableColumn",
    cellEditorFramework: "DateTimeCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD HH:mm"),
    filterValueGetter: (params) => {
      const date = moment(new Date(params.data.banDatetime));
      return date.isValid() ? date.format("YYYY/MM/DD HH:mm") : null;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateBanDatetime) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "発売元",
    field: "salesAgencyName",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateSalesAgencyName) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "販売元",
    field: "salesOriginName",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateSalesOriginName) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "JANCODE", field: "jancode" },
  {
    headerName: "タイトル", //12
    field: "title",
    editable: true,
    cellEditor: AutocompleteSelectCellEditor,
    valueFormatter: (params) => {
      if (params.value) {
        return params.value.label;
      }
      return "";
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const valueALbel = valueA.value ? valueA.label : nodeA.data.title;
      const valueBLbel = valueB.value ? valueB.label : nodeB.data.title;
      if (valueALbel == valueBLbel) return 0;
      return valueALbel > valueBLbel ? 1 : -1;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateTitle) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "商品名", field: "productName" }, //13
  { headerName: "商品名カナ", field: "productNameKana" }, //14
  {
    headerName: "上代", //15
    field: "retailPrice",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "掛率", //16
    field: "purchaseRate",
    type: "dpPercentColumn",
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "販売単価", //17
    field: "purchasePrice",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  { headerName: "Box商品", field: "pcs", type: "dpCheckmarkColumn" }, //18
  { headerName: "JANCODE(PSC)", field: "jancodePcs" }, //19
  { headerName: "上代（PCS)", field: "retailPricePcs", type: "dpNumericColumn" }, //20
  {
    headerName: "消費税", // 12
    field: "consumptionTaxRate",
    type: "dpPercentColumn",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ConsumptionTaxRates.all().map((ConsumptionTaxRate) => {
        return ConsumptionTaxRate.value;
      }),
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateConsumptionTaxRate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "Box入数", //22
    field: "inBoxQuantity",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "受注単位", //23
    field: "unit",
    type: "dpNumericColumn",
    cellEditor: "dpNumericCellEditor",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateUnit) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "商品区分", //24
    field: "productType",
    editable: true,
    cellEditor: "agSelectCellEditor",
    filter: "dpSelectionFilter",
    filterParams: { options: ProductTypes.all() },
    cellEditorPopup: true,
    cellEditorParams: {
      values: Object.keys(ProductTypes.refDataArray()),
      cellEditorPopup: true,
    },
    refData: ProductTypes.refDataArray(),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateProductType) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" }, //25
  {
    headerName: "上限数", //26
    field: "maximumQuantity",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "初回締後追加不可", //27
    field: "nonAdded",
    type: "dpCheckmarkColumn",
  },
  {
    headerName: "カテゴリー", //28
    field: "categoryId",
    editable: true,
    cellEditor: AutocompleteSelectCellEditor,
    filterValueGetter: (params) => {
      return params.data.categoryId.value ? String(params.data.categoryId.label) : String(params.data.categoryName);
    },
    valueFormatter: (params) => {
      if (params.value.label) {
        return params.value.label;
      } else {
        return params.data.categoryName;
      }
    },
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const valueALbel = valueA.value ? valueA.label : nodeA.data.categoryName;
      const valueBLbel = valueB.value ? valueB.label : nodeB.data.categoryName;
      if (valueALbel == valueBLbel) return 0;
      return valueALbel > valueBLbel ? 1 : -1;
    },
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateCategoryId) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "商品説明",
    field: "productDetail",
    editable: true,
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateProductDetail) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "CT中Box入数",
    field: "inCtBoxQuantity",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  { headerName: "パッケージサイズ", field: "packageSize" },
  {
    headerName: "CTsizeW",
    field: "ctSizeWidth",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "CTsizeD",
    field: "ctSizeDepth",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "CTsizeH",
    field: "ctSizeHeight",
    type: "dpNumericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: NumberValueFormatter(),
  },
  {
    headerName: "食品",
    field: "confectionery",
    type: "dpCheckmarkColumn",
  },
  { headerName: "賞味期限", field: "sellBy", filter: "agDateColumnFilter", type: "dpDateColumn" },
  { headerName: "原産国", field: "countryOfOrigin" },
  { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
  { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
  { headerName: "原材料", field: "rawMaterials" },
  { headerName: "コピーライト", field: "copyright", width: 150 },
  { headerName: "備考", field: "makerRemarks" },
];

export default { CafereoEditable };
export { CafereoEditable, SupplierEditable };
