<template>
  <v-card>
    <v-card-title>
      <template v-if="!inputModel.makerEntry">
        <span class="headline" v-if="inputModel.approval"><v-icon>mdi-pencil-plus-outline</v-icon>承認</span>
        <span class="headline" v-if="!inputModel.approval"><v-icon>mdi-pencil-outline</v-icon>取扱拒否</span>
      </template>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="productForm" lazy-validation>
        <template v-if="inputModel.approval">
          <section-block title="営業情報" separator>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-select
                  :items="constant.productRanks"
                  label="商品ランク"
                  v-model="updateModel.productRank"
                  :rules="[rules.required]"
                  dense
                  filled
                  attach
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="受注単位"
                  v-model.number="updateModel.orderUnit"
                  :rules="[rules.isNumber, rules.maxLength(8)]"
                  filled
                  dense
                  @change="updateModel.orderUnit = stringToNumber($event)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <dp-date-picker
                  type="datetime"
                  label="情報解禁日時"
                  :inteval="5"
                  v-model="updateModel.banDatetime"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-textarea
                  label="Cafereo備考"
                  v-model="updateModel.cafereoRemarks"
                  :rules="[rules.maxLength(100)]"
                  filled
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </section-block>
          <section-block title="販売店公開範囲" v-if="isCafereoUser" separator>
            <v-row dense>
              <v-col cols="12">
                <v-radio-group v-model="updateModel.scopeType" row>
                  <v-radio
                    :label="scope.text"
                    :value="scope.value"
                    v-for="scope in constant.scopeTypes"
                    :key="scope.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense v-if="updateModel.scopeType === 2">
              <v-col cols="12" sm="3">
                <v-btn v-model="updateModel.tohoLimited" :disabled="btnDisabled" @click="onAppendTohoLimited" dense
                  >東方のため流通限定</v-btn
                >
              </v-col>
            </v-row>
            <v-row dense v-if="updateModel.scopeType === 3">
              <v-col cols="12" sm="3">
                <v-btn
                  v-model="updateModel.overseasImpossible"
                  @click="onAppendOverseasImpossible"
                  :disabled="btnDisabled"
                  dense
                  >海外不可</v-btn
                >
              </v-col>
            </v-row>
            <v-row dense v-if="updateModel.scopeType !== 1">
              <v-col cols="12">
                <v-autocomplete
                  :items="scopeCustomerCorporations"
                  item-text="corporationName"
                  item-value="corporateCode"
                  label="会社名"
                  dense
                  filled
                  multiple
                  v-model="updateModel.scopeCorporateCodes"
                  attach
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense v-if="updateModel.scopeType !== 1">
              <v-col cols="12">
                <ag-grid-vue
                  id="ScopedCustomerSelector"
                  class="ag-theme-alpine"
                  :gridOptions="scopeGridOptions"
                  :rowData="scopedCustomerRecords"
                ></ag-grid-vue>
              </v-col>
            </v-row>
          </section-block>
        </template>
        <v-card-subtitle style="display: flex; height: 40px" v-if="!isMakerUser">
          <span>対象商品</span>
        </v-card-subtitle>
        <v-card-text>
          <ag-grid-vue
            class="ag-theme-alpine"
            :gridOptions="gridOptions"
            v-model="dataRecords"
            @row-data-changed="onRowDataChanged"
          ></ag-grid-vue>
        </v-card-text>
        <v-divider></v-divider>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCanselClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onRejectClick" v-if="!inputModel.approval" :disabled="btnDisabled">確定</v-btn>
      <v-btn color="primary" @click="onApprovalClick" v-if="inputModel.approval" :disabled="btnDisabled">確定</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="errorGrid"
      width="80%"
      height="80%"
      :columns="errorColmuns"
      @close="onCanselClick"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import ProductTypes from "../../consts/ProductTypes";
import ProductRanks from "../../consts/ProductRanks";
import ScopeTypes from "../../consts/ScopeTypes";
import CorporationTypes from "../../consts/CorporationTypes";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import MakerProductStatus from "../../consts/productStatus/MakerProductStatus";
import BaseProductStatus from "../../consts/productStatus/BaseProductStatus";
import ConvertUtils from "../../utils/ConvertUtils";
import SectionBlock from "../common/SectionBlock.vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import { DateCellEditor } from "../ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "ProductEntry",
  props: ["inputModel", "vendorCorporations", "columns", "rowData"],
  components: {
    AgGridVue,
    SectionBlock,
    ErrorGridDialog,
  },
  data() {
    const self = this;
    return {
      stringToNumber: ConvertUtils.stringToNumber,
      dataRecords: this.rowData,
      constant: {
        productTypes: ProductTypes.all(),
        productRanks: ProductRanks.all(),
        scopeTypes: ScopeTypes.all(),
        consumptionTaxes: [0, 8, 10],
        makerCorporationType: CorporationTypes.MAKER,
        baseProductStatus: BaseProductStatus,
      },
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: false,
          filter: false,
          editable: false,
        },
        columnDefs: this.columns,
        rowSelection: false,
        suppressCellSelection: true,
        singleClickEdit: true,
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
        domLayout: "autoHeight",
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpEditableColumn: EditableColumn,
          dpEditableDateColumn: EditableDateColumn,
        },
        frameworkComponents: {
          dpDateCellEditor: DateCellEditor,
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
        },
      },
      scopeGridOptions: {
        defaultColDef: {
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
        },
        columnDefs: [
          {
            headerName: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 50,
          },
          { headerName: "法人名", field: "corporationName", width: 300 },
          { headerName: "取引先名", field: "customerName", width: 300 },
        ],
        alwaysShowHorizontalScroll: true,
        suppressCellSelection: true,
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        pagination: true,
        paginationPageSize: null,
        localeText: AG_GRID_LOCALE_JA,
        domLayout: "autoHeight",
        getRowNodeId: (data) => data.customerCd,
        onRowDataChanged: (event) => {
          // 海外不可ボタンなどの自動チェック処理
          event.api.forEachNode((node) => {
            node.setSelected(false);
            for (let i = 0; i < self.updateModel.scopedCustomers.length; i++) {
              if (node.data.customerCd == self.updateModel.scopedCustomers[i]) node.setSelected(true);
            }
          });
        },
        onRowSelected(event) {
          if (event.node.isSelected()) {
            if (!self.updateModel.scopedCustomers.includes(event.data.customerCd)) {
              self.updateModel.scopedCustomers.push(event.data.customerCd);
            }
          } else {
            var index = self.updateModel.scopedCustomers.indexOf(event.data.customerCd);
            self.updateModel.scopedCustomers.splice(index, 1);
          }
        },
      },
      errorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      scopeCustomerCorporations: [],
      scopedCustomerRecords: [],
      updateModel: { scopeCorporateCodes: [] },
      btnDisabled: false,
      resaleAddDisabledFlg: false,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDecimal: Validation.isDecimal,
        isJancode: Validation.isJancode,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        validPscJan: (value) => this.validPscJan(value),
        validSellBy: (value) => this.validSellBy(value),
        validIsJancode: (value) => this.validIsJancode(value),
      },
    };
  },
  beforeMount() {
    this.dataRecords = this.rowData;
    this.scopeGridOptions.paginationPageSize = this.globalPageSize;
    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.init(this.inputModel);
  },
  computed: {
    productStatuses() {
      if (this.isMakerUser) {
        return MakerProductStatus.all();
      } else {
        return CafereoProductStatus.all();
      }
    },
    entryCorporationType() {
      if (this.isCafereoUser) {
        return CorporationTypes.CAFEREO;
      } else if (this.isMakerUser) {
        return CorporationTypes.MAKER;
      }
      return null;
    },
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    disabledResaleAdd() {
      let ret = false;
      if (this.updateModel.resales) {
        const ng = this.updateModel.resales?.find((elm) => elm.productStatus < BaseProductStatus.ADDITIONALRECEPTION);
        if (ng) ret = true;
      }
      return ret;
    },
  },
  watch: {
    rowData(value) {
      this.dataRecords = value;
    },
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
    async "updateModel.scopeCorporateCodes"(value) {
      if (value && this.isCafereoUser) {
        await this.findCustomers(value);
        //グリッドから消された取引先の選択は外す
        let deleteList = [];
        this.updateModel.scopedCustomers.forEach((e) => {
          let deleteFlg = true;
          this.scopeGridOptions.api.forEachNode((node) => {
            if (node.data.customerCd == e) {
              deleteFlg = false;
            }
          });
          if (deleteFlg) {
            deleteList.push(e);
          }
        });
        this.updateModel.scopedCustomers = this.updateModel.scopedCustomers.filter(function (v) {
          return !deleteList.includes(v);
        });
      }
    },
    async "updateModel.scopeType"() {
      this.updateModel.scopeCorporateCodes = [];
      this.updateModel.scopedCustomers = [];
      await this.findCustomers(this.updateModel.scopeCorporateCodes);
    },
    globalPageSize(size) {
      this.scopeGridOptions.api.paginationSetPageSize(size);
    },
  },
  methods: {
    // ***** 商品画像関連 *****
    onRowDataChanged() {
      this.gridOptions.columnApi.autoSizeAllColumns();
    },

    // ***** ボタン関連 *****
    async onRejectClick() {
      this.btnDisabled = true;
      const ok = await this.$dialog.confirm({
        title: `取扱拒否`,
        text: "取扱拒否にした商品は今後Delphoi上では一切取り扱いできなくなります。よろしいですか？",
      });
      if (ok) {
        try {
          this.loadingOn();
          let rejectModel = [];
          this.rowData.forEach((row) => {
            let elm = {};
            elm.productCd = row.productCd;
            elm.jancode = row.jancode;
            elm.updateDate = row.updateDate;
            rejectModel.push(elm);
          });
          const response = await this.$store.dispatch("product/reject", { products: rejectModel });
          let error = response.data?.header;
          let confirmRows = [];
          let confirmSuccessRecords = [];
          let errorRows = [];
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onApprovalSubmit", rejectModel);
              this.$dialog.notify.info(`選択された商品データを却下しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    jancode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              this.gridOptions.api.forEachNode((node) => {
                let row = node.data;
                errorRows.forEach((errorRow) => {
                  if (errorRow.id != row.id) {
                    confirmSuccessRecords.push(row);
                    confirmRows.push({ id: row.id });
                  }
                });
              });
              // 一覧部のレコードを更新
              // if (confirmSuccessRecords.length > 0) this.gridApi.applyTransaction({ update: confirmSuccessRecords });
              // if (confirmRows.length > 0) this.gridOptions.applyTransaction({ remove: confirmRows });
              // エラー表示
              if (errorRows.length > 0) {
                console.log("ProductSearch::onSubmit", errorRows);
                // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`却下処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ProductEntry::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          // フォーム内容リセット
          if (this.$refs.productForm) {
            this.$refs.productForm.reset();
            this.$refs.productForm.resetValidation();
            this.updateModel = {};
          }
          this.loadingOff();
        }
      }
      this.btnDisabled = false;
    },
    async onApprovalClick() {
      this.btnDisabled = true;
      if (this.validate()) {
        try {
          this.loadingOn();

          let entryModel = [];
          this.rowData.forEach((row) => {
            let elm = {};
            elm.productCd = row.productCd;
            elm.jancode = row.jancode;
            elm.productRank = this.updateModel.productRank;
            elm.orderUnit = this.updateModel.orderUnit;
            elm.cafereoRemarks = this.updateModel.cafereoRemarks ? this.updateModel.cafereoRemarks : "";
            elm.scopeType = this.updateModel.scopeType;
            elm.scopedCustomers = this.updateModel.scopedCustomers;
            elm.banDatetime = this.updateModel.banDatetime
              ? moment(this.updateModel.banDatetime, "YYYY/MM/DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
              : row.banDatetime;
            elm.updateDate = row.updateDate;
            entryModel.push(elm);
          });
          const response = await this.$store.dispatch("product/approval", { productApprovals: entryModel });
          let error = response.data?.header;
          let confirmRows = [];
          let confirmSuccessRecords = [];
          let errorRows = [];
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onApprovalSubmit", entryModel);
              this.$dialog.notify.info(`選択された商品データを承認しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    jancode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              this.gridOptions.api.forEachNode((node) => {
                let row = node.data;
                errorRows.forEach((errorRow) => {
                  if (errorRow.id != row.id) {
                    confirmSuccessRecords.push(row);
                    confirmRows.push({ id: row.id });
                  }
                });
              });
              // 一覧部のレコードを更新
              // if (confirmSuccessRecords.length > 0) this.gridApi.applyTransaction({ update: confirmSuccessRecords });
              // if (confirmRows.length > 0) this.gridOptions.applyTransaction({ remove: confirmRows });
              // エラー表示
              if (errorRows.length > 0) {
                console.log("ProductSearch::onSubmit", errorRows);
                // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`承認処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ProductEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          // フォーム内容リセット
          if (this.$refs.productForm) {
            this.$refs.productForm.reset();
            this.$refs.productForm.resetValidation();
            this.updateModel = {};
          }
          this.loadingOff();
        }
      }
      this.btnDisabled = false;
    },
    onCanselClick() {
      //if (this.$refs.productForm) {
      this.$refs.productForm.resetValidation();
      this.$refs.productForm.reset();
      // 値が残るとコンポーンネントのWatchの値が変わらないので表示されない
      this.updateModel = {};
      //}
      this.$emit("onApprovDialogClose");
    },
    async onAppendOverseasImpossible() {
      try {
        this.btnDisabled = true;
        this.loadingOn();
        const response = await this.$store.dispatch("customer/overseasImpossible");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendOverseasImpossible", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOff();
      }
    },
    async onAppendTohoLimited() {
      try {
        this.btnDisabled = true;
        this.loadingOn();
        const response = await this.$store.dispatch("customer/tohoLimited");
        this.disclosureRangeProc(response.data.contents.customers);
      } catch (error) {
        console.error("onAppendOverseasImpossible", error);
        this.apiRequestError(error);
      } finally {
        this.btnDisabled = false;
        this.loadingOff();
      }
    },
    async disclosureRangeProc(customers) {
      // 更新モデルとグリッドに追加（チェックはonRowDataで実施）
      let corpChange = false;
      customers.forEach((row) => {
        if (!this.updateModel.scopedCustomers.includes(row.customerCd)) {
          this.updateModel.scopedCustomers.push(row.customerCd);
        }
        if (!this.updateModel.scopeCorporateCodes.includes(row.corporationCd)) {
          this.updateModel.scopeCorporateCodes.push(row.corporationCd);
          corpChange = true;
        }
      });
      if (!corpChange) {
        this.findCustomers(this.updateModel.scopeCorporateCodes);
      }
    },
    // 初期化処理
    async init(inputModel) {
      //
      this.initUpdateModel(inputModel);

      if (this.$refs.productForm) this.$refs.productForm.resetValidation();
      if (this.isCafereoUser) {
        const params = { customerFlg: true };
        const response = await this.$store.dispatch("corporation/suggest", params);
        this.scopeCustomerCorporations = response.data.contents.corporations;
        // 公開範囲販売店の取得（この後はupdateModel.scopeCorporateCodesをwatchして更新）
        if (this.updateModel.corporateCode && this.updateModel.scopeCorporateCodes) {
          await this.findCustomers(this.updateModel.scopeCorporateCodes);
        }
      }
    },
    //
    async initUpdateModel(inputModel) {
      this.btnDisabled = false;
      this.previewImages = [];
      if (inputModel.createNew) {
        // 新規登録
        this.updateModel = JSON.parse(JSON.stringify(this.defaultModel));
      } else {
        // 編集
        this.updateModel = JSON.parse(JSON.stringify(inputModel));

        // 公開範囲の編集
        let corpCds = [];
        if (this.updateModel.scopeType !== 1 && this.isCafereoUser) {
          console.log("customers:", this.updateModel.scopedCustomers);
          const response = await this.$store.dispatch("customer/suggestPost", {
            customerCds: this.updateModel.scopedCustomers,
          });
          let customers = response.data.contents.customers;
          for (const elm of customers) {
            if (!corpCds.includes(elm.corporationCd)) {
              corpCds.push(elm.corporationCd);
            }
          }
          // 一度消えるため、ここでコピー
          this.updateModel.scopedCustomers = this.inputModel.scopedCustomers;
          this.updateModel.scopeCorporateCodes = corpCds;
        }
      }
    },
    // 公開範囲のグリッド更新
    async findCustomers(corporateCodes) {
      try {
        this.loadingOn();
        this.scopedCustomerRecords = [];
        for (let i = 0; i < corporateCodes.length; i++) {
          const response = await this.$store.dispatch("customer/suggest", { CorporationCd: corporateCodes[i] });
          if (response.data.contents.customers)
            this.scopedCustomerRecords = this.scopedCustomerRecords.concat(response.data.contents.customers);
        }
      } catch (error) {
        console.error("UserEntry::findCustomers", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },

    // ***** バリデーション関連 *****
    validate() {
      const isValid = this.$refs.productForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
  },
};
</script>
