<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-book-information-variant</v-icon>商品詳細</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-row no-gutters class="mb-2">
          <v-col cols="2" xs="12" v-for="n in images" :key="n">
            <v-card class="mt-1 mr-1" tile elevation="1">
              <v-img contain max-height="300" max-width="300" :lazy-src="n" :src="n"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="商品ステータス" :value="selectedRowValue['productStatus']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="登録日" :value="selectedRowValue['createDate']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="更新日" :value="selectedRowValue['updateDate']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="発売元" :value="selectedRowValue['salesAgencyName']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="販売元" :value="selectedRowValue['salesOriginName']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="JANCODE" :value="selectedRowValue['jancode']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="タイトル" :value="selectedRowValue['title']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="商品名" :value="selectedRowValue['productName']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="商品名カナ" :value="selectedRowValue['productNameKana']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="上代" :value="selectedRowValue['retailPrice']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="卸掛率" :value="selectedRowValue['orderRate']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="卸価格" :value="selectedRowValue['unitPrice']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="発注締日" :value="selectedRowValue['cafereoClosingDate']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="発売日" :value="selectedRowValue['releaseDate']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="カテゴリー" :value="selectedRowValue['categoryName']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="商品区分" :value="selectedRowValue['productType']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="Box入数" :value="selectedRowValue['inBoxQuantity']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="受注単位" :value="selectedRowValue['orderUnit']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card
              name="減数の可能性あり"
              :value="selectedRowValue['maximumPossibility']"
            ></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="初回締後追加不可" :value="selectedRowValue['nonAdded']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="8" xs="12">
            <product-detail-card
              name="商品説明"
              :value="selectedRowValue['productDetail']"
              colSize="2"
            ></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="CT中Box入数" :value="selectedRowValue['inCtBoxQuantity']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="CT中pcs入数" :value="selectedRowValue['inCtPcsQuantity']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="パッケージサイズ" :value="selectedRowValue['packageSize']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="CTsizeW" :value="selectedRowValue['ctSizeWidth']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="CTsizeD" :value="selectedRowValue['ctSizeDepth']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="CTsizeH" :value="selectedRowValue['ctSizeHeight']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card name="食品" :value="selectedRowValue['confectionery']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="賞味期限" :value="selectedRowValue['sellBy']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="原産国" :value="selectedRowValue['countryOfOrigin']"></product-detail-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" xs="12">
            <product-detail-card
              name="飛行機検査可不可"
              :value="selectedRowValue['airplaneInspection']"
            ></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="危険物" :value="selectedRowValue['hazardousMaterial']"></product-detail-card>
          </v-col>
          <v-col cols="4" xs="12">
            <product-detail-card name="原材料" :value="selectedRowValue['rawMaterials']"></product-detail-card>
          </v-col>
          <v-col cols="8" xs="12">
            <product-detail-card
              name="コピーライト"
              :value="selectedRowValue['copyright']"
              colSize="2"
            ></product-detail-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('onSubmit', selectedRow)" :disabled="!disabledAddCart">注文表追加</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProductDetailCard from "./ProductDetailCard.vue";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import ProductTypes from "../../consts/ProductTypes";

export default {
  name: "ProductDetalsForm",
  props: ["selectedRow", "selectedRowValue"],
  components: {
    ProductDetailCard,
  },
  computed: {
    productStatus() {
      return VendorProductStatus.of(this.selectedRow?.productStatus);
    },
    productTypes() {
      return ProductTypes.of(this.selectedRow?.productType);
    },
    disabledAddCart() {
      // Note: vendor only.
      return this.selectedRow?.productStatus === 1 || this.selectedRow?.productStatus === 4;
    },
    images() {
      let images = [];
      if (this.selectedRow && Array.isArray(this.selectedRow.images)) {
        this.selectedRow.images.forEach((image) => {
          images.push(image.split(",")[1]);
        });
      }
      return images;
    },
  },
};
</script>
