<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-refresh</v-icon>画像情報編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="productImageForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="ファイル名"
                v-model="afterFileName"
                :rules="[rules.required, rules.maxLength(150), rules.isFileName]"
              >
              </v-text-field>
              <input type="text" style="display: none" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onClose">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdate">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
export default {
  name: "ProductImageEdit",
  props: ["fileName"],
  data() {
    return {
      loading: false,
      beforeFileName: "",
      afterFileName: "",
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isFileName: Validation.isFileName,
      },
    };
  },
  mounted() {
    this.beforeFileName = this.fileName;
    this.afterFileName = this.fileName;
    if (this.$refs.productImageForm) this.$refs.productImageForm.resetValidation();
  },
  watch: {
    fileName(value) {
      this.beforeFileName = value;
      this.afterFileName = value;
    },
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    loadingOnSelf() {
      this.loading = true;
    },
    loadingOffSelf() {
      this.loading = false;
    },
    async onUpdate() {
      //
      if (this.validate()) {
        let resetFlg = true;
        try {
          this.loadingOnSelf();
          const response = await this.$store.dispatch("productImage/update", {
            productImage: {
              beforeFileName: this.beforeFileName,
              afterFileName: this.afterFileName,
            },
          });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit");
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              resetFlg = false;
              var message = "";
              Object.keys(response.data?.header.messages).forEach(function (key) {
                message += response.data?.header.messages[key].join("<br>");
              });
              if (message != "") {
                this.$dialog.warning({
                  title: `商品画像編集`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ProductImageEdit::onUpdate", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOffSelf();
          // フォーム内容リセット
          if (this.$refs.productImageForm && resetFlg) {
            this.$refs.productImageForm.reset();
            this.$refs.productImageForm.resetValidation();
          }
        }
      }
    },
    onClose() {
      // 編集内容がのこるので
      this.afterFileName = this.fileName;
      this.$emit("onDialogClose");
    },
    validate() {
      let isValid = this.$refs.productImageForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
