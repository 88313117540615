<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>{{ getTitle }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-checkbox
                v-if="isCafereoUser"
                label="検索上限無し"
                v-model="searchModel.fetchLimitOverFlg"
                dense
              ></v-checkbox>
            </v-row>
            <v-row dense>
              <v-col>
                <v-select
                  v-if="isRegisteredMode"
                  clearable
                  label="商品ステータス"
                  v-model="searchModel.productStatus"
                  :items="productStatuses"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense v-if="!isVendorUser">
              <v-col>
                <dp-date-picker
                  v-model="searchModel.createDateFrom"
                  type="date"
                  label="登録日付From"
                  :rules="[rules.dateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.createDateTo"
                  type="date"
                  label="登録日付To"
                  :rules="[rules.dateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.banDateFrom"
                  type="date"
                  label="情報解禁日From"
                  :rules="[rules.banDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.banDateTo"
                  type="date"
                  label="情報解禁日To"
                  :rules="[rules.banDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <cafereo-user-field
                v-if="!isRegisteredMode && !isMakerUser"
                ref="customerStaff"
                label="担当者"
                :value="defaultCustomerStaff"
                v-model="searchModel.customerStaff"
                :attach="false"
              ></cafereo-user-field>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.supplierShortName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="仕入先略称"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="!isMakerUser && isRegisteredMode"
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  dense
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="(isRegisteredMode && isCafereoUser) || isVendorUser"
                  v-model="searchModel.title"
                  label="タイトル"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="(isRegisteredMode && isCafereoUser) || isVendorUser"
                  v-model="searchModel.productName"
                  label="商品名"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="(isRegisteredMode && isCafereoUser) || isVendorUser"
                  v-model="searchModel.salesAgencyName"
                  label="発売元"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-if="(isRegisteredMode && isCafereoUser) || isVendorUser"
                  v-model="searchModel.salesOriginName"
                  label="販売元"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <cafereo-user-field
                v-if="isRegisteredMode && isCafereoUser"
                ref="customerStaff"
                label="仕入担当者"
                v-model="searchModel.supplierChargeCD"
                :attach="false"
              ></cafereo-user-field>
            </v-row>
            <v-row dense>
              <v-textarea
                v-if="isRegisteredMode && isCafereoUser"
                v-model="searchModel.cafereoRemarks"
                label="カフェレオ備考"
                dense
                class="mx-2"
                rows="1"
                :rules="[rules.maxLength(256)]"
              ></v-textarea>
            </v-row>
            {{
              allowedAction(constants.vendorActions) && !listSelected
                ? "※検索をするとツリー選択が利用できなくなります。"
                : ""
            }}
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-menu-button
          v-if="allowedAction(constants.amazonActions) && isRegisteredMode"
          text="Amazon専用"
          icon="mdi-amazon"
        >
          <v-list dense>
            <v-list-item @click="onBtnAsinRequest" dense :disabled="!rowsSelected">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-send</v-icon></v-list-item-avatar>
              <v-list-item-title>ASIN発行依頼</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnAsinImport" dense>
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-upload</v-icon></v-list-item-avatar>
              <v-list-item-title>ASIN取り込み</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="onBtnNyp" dense :disabled="!rowsSelected">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-send</v-icon></v-list-item-avatar>
              <v-list-item-title>再販NYPシート</v-list-item-title>
            </v-list-item>
          </v-list>
        </tooltip-icon-menu-button>
        <datepicker-confirm-grid-dialog
          ref="confirmGrid"
          :autoClose="false"
          :expend="true"
          width="1100px"
          height="350px"
          @expand="$refs.confirmGrid.options.width = $event ? '100%' : '800px'"
        ></datepicker-confirm-grid-dialog>
        <v-dialog v-model="asinImportDialog.show" :max-width="asinImportDialog.width" persistent scrollable>
          <amazon-asin-import
            :height="asinImportDialog.height"
            @complete="onAsinImportComplete"
            @expand="
              asinImportDialog.width = $event ? '100%' : '800px';
              asinImportDialog.height = $event ? '600px' : '300px';
            "
            v-if="asinImportDialog.show"
          ></amazon-asin-import>
        </v-dialog>
        <v-divider v-if="allowedAction(constants.amazonActions) && isRegisteredMode" vertical></v-divider>
        <!-- カフェレオはメニューに全て表示 -->
        <!-- アニメイト、ヨドバシカメラ、ソフマップ、ヴィレッジバンガード、東急ハンズは通常アイコン -->
        <!-- ロフトはEC用があるためメニュー表示 -->
        <tooltip-icon-menu-button
          v-if="allowedAction(constants.ediActions) && isRegisteredMode"
          text="各フォーマットダウンロード"
          icon="mdi-file-download"
        >
          <v-list dense>
            <v-list-item
              @click="onBtnDownloadAnimate"
              dense
              :disabled="!rowsSelected"
              v-if="allowedAction(['C010105'])"
            >
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>アニメイト</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="onBtnDownloadYodobashi"
              dense
              :disabled="!rowsSelected"
              v-if="allowedAction(['C010106'])"
            >
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ヨドバシカメラ</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="onBtnDownloadYodobashiEc"
              dense
              :disabled="!rowsSelected"
              v-if="allowedAction(['C010106'])"
            >
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ヨドバシカメラ(EC用)</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadSofmap" dense :disabled="!rowsSelected" v-if="allowedAction(['C010107'])">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ソフマップ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadVVG" dense :disabled="!rowsSelected" v-if="allowedAction(['C010108'])">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ヴィレッジヴァンガード</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadHands" dense :disabled="!rowsSelected" v-if="allowedAction(['C010109'])">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>東急ハンズ</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadLoft" dense :disabled="!rowsSelected" v-if="allowedAction(['C010110'])">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ロフト</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadLoftEc" dense :disabled="!rowsSelected" v-if="allowedAction(['C010110'])">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>ロフト(EC用)</v-list-item-title>
            </v-list-item>
          </v-list>
        </tooltip-icon-menu-button>
        <v-divider v-if="allowedAction(constants.updateActions) && !isRegisteredMode" vertical></v-divider>
        <tooltip-icon-menu-button
          v-if="allowedAction(constants.updateActions) && !isRegisteredMode"
          text="承認・取扱拒否"
          icon="mdi-check"
          :disabled="!isApproval"
        >
          <v-list dense>
            <v-list-item @click="onBtnApproval(true)" dense>
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-check</v-icon></v-list-item-avatar>
              <v-list-item-title>承認</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnApproval(false)" dense>
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-close</v-icon></v-list-item-avatar>
              <v-list-item-title>取扱拒否</v-list-item-title>
            </v-list-item>
          </v-list>
        </tooltip-icon-menu-button>
        <v-dialog v-model="approvalDialog" max-width="1800px" persistent scrollable>
          <product-confirm-grid-dialog
            ref="productConfirmGrid"
            v-if="approvalDialog"
            :inputModel="productModel"
            :rowData="selectionRows"
            :vendorCorporations="vendorCorporations"
            :columns="cafereoApprovalColumns"
            @onApprovDialogClose="onApprovDialogClose"
            @onApprovalSubmit="onApprovalSubmit"
            @expand="percentDialog.width = $event ? '100%' : '800px'"
          >
            ></product-confirm-grid-dialog
          >
        </v-dialog>
        <v-divider v-if="allowedAction(constants.updateActions) && !isRegisteredMode" vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(constants.vendorActions)"
          icon="mdi-cart-plus"
          @click="onBtnInportCart"
          :disabled="disabledAddCart"
          >注文票追加</tooltip-icon-button
        >
        <tooltip-icon-button v-if="allowedAction(constants.vendorActions)" icon="mdi-cart" @click="onBtnOpenCart"
          >注文票表示</tooltip-icon-button
        >
        <v-dialog v-model="cartDialog.show" :max-width="cartDialog.width" persistent scrollable>
          <product-cart
            v-if="cartDialog.show"
            ref="productCart"
            :initialOrders="initialCart"
            @onClose="onCartDialogClose"
            @onSubmit="onCartEntrySubmit"
            :shareOrderRecords="orderRecords"
          ></product-cart>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction(constants.createActions) && isRegisteredMode"
          icon="mdi-database-import"
          @click="onBtnImport"
          >一括取り込み</tooltip-icon-button
        >
        <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
          <product-import
            @complete="onImportComplete"
            @expand="importDialog.width = $event ? '100%' : '800px'"
            v-if="importDialog.show"
          ></product-import>
        </v-dialog>
        <v-divider
          v-if="
            (allowedAction(constants.createActions) || allowedAction(constants.supplierActions)) && isRegisteredMode
          "
          vertical
        ></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(constants.supplierActions)"
          :disabled="selectionRows.length === 0"
          icon="mdi-database-settings"
          @click="onBtnSwitchSock"
          >在庫状況設定</tooltip-icon-button
        >
        <v-dialog v-model="makerStockDialog.show" :max-width="makerStockDialog.width" persistent scrollable>
          <apply-maker-stock
            :products="selectionRows"
            @onClose="onMakerStockDialogClose"
            @onSubmit="onMakerStockDialogSubmit"
            v-if="makerStockDialog.show"
          ></apply-maker-stock>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction(constants.createActions) && isRegisteredMode"
          icon="mdi-pencil-plus-outline"
          @click="onBtnInsert"
          >新規登録</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="
            allowedAction(constants.supplierActions) || (isRegisteredMode && allowedAction(constants.updateActions))
          "
          :disabled="!selectedRow || makerUpdated"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
          <product-entry
            ref="productEntry"
            v-if="editDialog"
            :inputModel="productModel"
            :vendorCorporations="vendorCorporations"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            @expand="percentDialog.width = $event ? '100%' : '800px'"
          >
          </product-entry>
        </v-dialog>
        <tooltip-icon-button
          v-if="
            allowedAction(constants.supplierActions) || (isRegisteredMode && allowedAction(constants.updateActions))
          "
          :disabled="!enabledProductEntry"
          icon="mdi-database-edit"
          @click="onBtnBulkEdit"
          >一括編集</tooltip-icon-button
        >
        <v-dialog v-model="editBulkDialog" max-width="1800px" persistent scrollable>
          <product-bulk-edit
            ref="productEntry"
            v-if="editBulkDialog"
            :inputModel="selectionRows"
            @onDialogClose="onProductBulkEditDialogClose(false)"
            @onBulkEdit="onBulkEdit"
            @expand="percentDialog.width = $event ? '100%' : '800px'"
          >
          </product-bulk-edit>
        </v-dialog>
        <tooltip-icon-button
          v-if="
            (allowedAction(constants.deleteActions) && isRegisteredMode) || allowedAction(constants.supplierActions)
          "
          :disabled="!rowsSelected"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="allowedAction(constants.updateRateActions) && isRegisteredMode"
          :disabled="!selectedRow"
          icon="mdi-label-percent"
          @click="onBtnPercent"
          >法人別卸掛率更新</tooltip-icon-button
        >
        <v-dialog v-model="percentDialog.show" :max-width="percentDialog.width" persistent scrollable>
          <product-company-multiplication-rate
            :inputModel="selectedRow"
            v-if="percentDialog.show"
            :corporetionRateModel="corporetionRateModel"
            @onDialogClose="onPercentDialogClose"
            @onEditSubmit="onPercentDialogClose"
            @expand="percentDialog.width = $event ? '100%' : '800px'"
          >
          </product-company-multiplication-rate>
        </v-dialog>
        <tooltip-icon-button
          v-if="allowedAction(constants.referenceActions) && isRegisteredMode"
          :disabled="!rowsSelected"
          icon="mdi-link"
          @click="onBtnLink"
          >リンク取得</tooltip-icon-button
        >
        <v-dialog v-model="linkDialog" max-width="500px" persistent scrollable>
          <v-card v-if="linkDialog">
            <v-card-title>
              <span class="headline"><v-icon>mdi-link</v-icon>リンク取得</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form>
                <v-container style="max-width: inherit">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="商品リンク" v-model="productLink" filled dense readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="secondary" @click="onLinkDialogClose()">閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <tooltip-icon-button
          icon="mdi-file-download"
          @click="onBtnDownload4Vendor"
          v-if="allowedAction(constants.productRegistVendorActions)"
          :disabled="!rowsSelected"
          >商品登録フォーマットダウンロード</tooltip-icon-button
        >
        <tooltip-icon-menu-button
          v-if="allowedAction(constants.productRegistEcVendorActions)"
          text="商品登録フォーマットダウンロード"
          icon="mdi-file-download"
        >
          <v-list dense>
            <v-list-item @click="onBtnDownload4Vendor" dense :disabled="!rowsSelected">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>通常フォーマット</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onBtnDownloadEc4Vendor" dense :disabled="!rowsSelected">
              <v-list-item-avatar class="ma-0"><v-icon small>mdi-file-download</v-icon></v-list-item-avatar>
              <v-list-item-title>EC用フォーマット</v-list-item-title>
            </v-list-item>
          </v-list>
        </tooltip-icon-menu-button>

        <tooltip-icon-button
          icon="mdi-image-multiple"
          @click="onBtnImageExport"
          v-if="
            allowedAction(constants.vendorActions) || (allowedAction(constants.referenceActions) && isRegisteredMode)
          "
          :disabled="!rowsSelected"
          >画像一括ダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport">CSVダウンロード</tooltip-icon-button>
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button
          v-if="allowedAction(constants.vendorActions)"
          icon="mdi-format-list-text"
          v-model="listSelected"
          >一覧表示切替</tooltip-icon-toggle-button
        >
        <tooltip-icon-toggle-button
          icon="mdi-information-outline"
          v-model="detailSelected"
          :disabled="selectionRows.length > 1"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        id="ProductList"
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="(!detailSelected || !infoMaximum) && !listSelected"
      >
        <ag-grid-vue
          id="ProductList"
          style="height: 98%"
          class="ag-theme-alpine"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.productCd"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        id="ProductImageList"
        style="height: 100%; flex-basis: auto"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="(!detailSelected || !infoMaximum) && listSelected"
        class="my-2"
      >
        <v-row>
          <v-toolbar dense>
            <tooltip-icon-toggle-button icon="mdi-file-tree" v-model="treeSelected"
              >ツリー表示</tooltip-icon-toggle-button
            >
            <v-spacer></v-spacer>
            <tooltip-icon-button icon="mdi-checkbox-marked" @click="onBtnAllCheck">すべてチェック</tooltip-icon-button>
            <tooltip-icon-button icon="mdi-checkbox-blank-outline" @click="onBtnAllUncheck"
              >すべてチェックを外す</tooltip-icon-button
            >
          </v-toolbar>
        </v-row>
        <v-row>
          <v-col v-if="treeSelected" style="flex-basis: auto; display: flex; height: 100%" cols="3 : 12">
            <v-treeview
              selectable
              :items="treeItems"
              :open="treeOpen"
              @input="onclickTreeNode"
              v-model="categoryTree"
              dense
              style="color: rgba(0, 0, 0, 0.8); font-size: 13px"
              item-disabled="locked"
            ></v-treeview>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
          </v-col>
          <v-col style="height: 100%; flex-basis: auto" :cols="treeSelected ? 9 : 12">
            <v-row>
              <v-col cols="12" sm="3" v-for="displayModel in displayModels" :key="displayModel.id">
                <product-image-card
                  ref="imgCard"
                  :inputModel="displayModel"
                  :isImgClickCheck="false"
                  :isClickSelected="true"
                  @onCheck="onCheck"
                  @onSelected="onSelectedImg"
                ></product-image-card>
              </v-col>
              <v-col cols="12">
                <v-pagination v-model="pageNumber" :length="maxPage" @input="changePage"></v-pagination>
              </v-col>
            </v-row>
          </v-col>

          <v-dialog v-model="detailDialog" max-width="100%" persistent scrollable>
            <product-detals-form
              :selectedRow="imgSelected"
              :selectedRowValue="imgSelectedValue"
              @onClose="onDetailDialogClose"
              @onSubmit="onCartAddSubmit"
              v-if="detailDialog"
            >
            </product-detals-form>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="ProductInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <product-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :imageList="selectedImageList"
          :details="selectedDetails"
          :productCd="selectedProductCd"
          :approval="!isRegisteredMode && !isMakerUser"
        ></product-infos>
      </v-col>
    </v-row>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <error-grid-dialog ref="errorGrid" width="80%" height="80%" :columns="asinErrorColmuns"></error-grid-dialog>
    <error-grid-dialog ref="errorGrid" width="80%" height="80%" :columns="nypErrorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import FileUtils from "../../utils/FileUtils";
import ProductInfos from "./../../components/product/ProductInfos.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import ProductImport from "../../components/product/ProductImport.vue";
import ProductBulkEdit from "../../components/product/ProductBulkEdit.vue";
import ProductCompanyMultiplicationRate from "../../components/product/ProductCompanyMultiplicationRate.vue";
import AmazonAsinImport from "../../components/product/amazon/AsinImport.vue";
import ProductConfirmGridDialog from "../../components/product/ProductConfirmGridDialog.vue";
import DatepickerConfirmGridDialog from "../../components/common/DatepickerConfirmGridDialog.vue";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { getDisplayDetails2, getProductDisplayDetailValues2, typeFormat } from "../../models/ag-grid/helpers";
import ProductEntry from "../../components/product/ProductEntry.vue";
import ProductImageCard from "../../components/product/ProductImageCard.vue";
import ApplyMakerStock from "../../components/product/ApplyMakerStock.vue";
import ProductCart from "../../components/product/ProductCart.vue";
import ProductDetalsForm from "../../components/product/ProductDetalsForm.vue";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import MakerProductStatus from "../../consts/productStatus/MakerProductStatus";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import BaseProductStatus from "../../consts/productStatus/BaseProductStatus";
import ProductTypes from "../../consts/ProductTypes";
import ProductRecordTypes from "../../consts/ProductRecordTypes";
import ScopeTypes from "../../consts/ScopeTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { DateCellEditor } from "../../components/ag-grid/cellEditors";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableDateColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { ProductClassCellRenderer } from "../../models/ag-grid/cellRenderers";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "ProductSearch",
  props: ["linkId"],
  components: {
    AgGridVue,
    ProductInfos,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    TooltipIconMenuButton,
    ProductImport,
    ProductBulkEdit,
    ProductCompanyMultiplicationRate,
    AmazonAsinImport,
    ProductConfirmGridDialog,
    ProductEntry,
    ProductImageCard,
    ApplyMakerStock,
    ProductCart,
    ProductDetalsForm,
    ErrorGridDialog,
    DatepickerConfirmGridDialog,
    CafereoUserField,
  },
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C010101"],
        referenceActions: ["C010116", "C010118"],
        createActions: ["C010112", "C010115"],
        amazonActions: ["C010102", "C010103", "C010104"],
        ediActions: ["C010105", "C010106", "C010107", "C010108", "C010109", "C010110", "C010111"],
        updateActions: ["C010113", "C010120"],
        updateRateActions: ["C010117"],
        deleteActions: ["C010114"],
        supplierActions: ["M010302", "M010304", "M010305", "M030103", "M010307"],
        vendorActions: [
          "V010102",
          "V010103",
          "V010104",
          "V010105",
          "V010106",
          "V010107",
          "V010108",
          "V010109",
          "V010110",
          "V010111",
          "V010112",
          "V010113",
          "V010114",
        ],
        productRegistVendorActions: ["V010104", "V010107", "V010108", "V010109"],
        productRegistEcVendorActions: ["V010105", "V010106", "V010110", "V010111"],
      },
      serviceStting: this.$store.getters["security/serviceSetting"],
      makerCorporations: [],
      vendorCorporations: [],
      corporetionRateModel: [],
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      rules: {
        maxLength: Validation.maxLength,
        required: Validation.required,
        dateFrom: (value) => this.dateFromRules(value),
        dateTo: (value) => this.dateToRules(value),
        banDateFrom: (value) => this.banDateFromRules(value),
        banDateTo: (value) => this.banDateToRules(value),
        isJancodeSearchList: Validation.isJancodeSearchList,
      },
      validSeearchForm: null,
      columnTypes: {
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableDateColumn: EditableDateColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
        dpDateCellEditor: DateCellEditor,
        dpBooleanFilter: BooleanFilter,
      },
      infoMaximum: false,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      searchModel: {
        productStatus: null,
        createDateFrom: null,
        createDateTo: null,
        banDateFrom: null,
        banDateTo: null,
        linkId: null,
        customerStaff: null,
        supplierShortName: null,
      },
      productModel: {
        createNew: false,
        imageList: [],
        approval: false,
        ProductCode: null,
        ProductName: null,
        Vender: null,
        Maker: null,
        RetailPrice: null,
        PurchasePrice: null,
        PurchaseRate: null,
        MakerCode: null,
        Unit: null,
        JanCodeBox: null,
        JanCodePcs: null,
        OrderClosingDate: null,
        ReleaseDate: null,
        Genre: null,
        Category: null,
        ProductDescription: null,
        productType: null,
        ProductNameKana: null,
        ProductNameEnglish: null,
        PackageSize: null,
        InBoxNumber: null,
        OrderUnit: null,
        MaximumNumber: null,
        ProductStatus: null,
        MakerStock: null,
        BanDatetime: null,
        DealerBanDatetime: null,
        CafereoClosingDate: null,
        StrictReleaseDate: false,
        Reduced: false,
        Confectionery: false,
        SellBy: null,
        NonAdded: false,
        Limited: false,
        AccessoriesJanCode: null,
        MakerRemarks: null,
        UpdateDatetime: null,
        UpdateUser: null,
        CreateDatetime: null,
        CreateUser: null,
        InCtBoxNumber: null,
        InCtPcsNumber: null,
        CtSizeWidth: null,
        CtSizeDepth: null,
        CtSizeHeight: null,
        ApprovalUser: null,
        ApprovalDatetime: null,
        ProductLank: null,
        VendorOpenScopeWhite: null,
        VendorOpenScopeBlack: null,
        CafereoRemarks: null,
        hazardousMaterial: false,
        rawMaterials: null,
        NoDiscount: false,
      },
      asinImportDialog: { show: false, width: "800px", height: "300px" },
      editDialog: false,
      editBulkDialog: false,
      importDialog: { show: false, width: "800px" },
      linkDialog: false,
      approvalDialog: false,
      productLink: "http://localhost/direct/products/{id}",
      percentDialog: { show: false, width: "800px" },
      selectionRows: [],
      isApproval: false,
      listSelected: false, // 画像一覧画面の選択（販売店用）
      pageNumber: 1,
      maxPage: 1,
      gridStyle: { height: "95%" },
      imageListModels: [],
      cartDialog: { show: false, width: "1800px" },
      makerStockDialog: { show: false, width: "1100px" },
      displayModels: [],
      selectedCards: [],
      imgSelected: null,
      imgSelectedValue: null,
      treeSelected: true,
      detailDialog: false,
      treeItems: [],
      categoryTree: [1],
      treeOpen: [1],
      initialCart: [],
      asinRows: [],
      nypRows: [],
      defaultCustomerStaff: null,
      // Cafereo商品一覧
      cafereoColumns: [
        {
          headerName: "登録日",
          field: "createDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "仕入先略称",
          field: "supplierShortName",
        },
        { headerName: "JANCODE", field: "jancode" },

        { headerName: "商品ランク", field: "productRank" },
        {
          headerName: "タイトル",
          field: "title",
          filterParams: IncludeFilter,
        },
        {
          headerName: "商品名",
          field: "productName",
          filterParams: IncludeFilter,
        },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
        },
        { headerName: "受注締日", field: "cafereoClosingDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "カテゴリー", field: "categoryName" },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" },
        { headerName: "仕入担当者", field: "supplierChargeName" },
        {
          headerName: "PCS",
          field: "pcs",
          type: "dpCheckmarkColumn",
        },
        { headerName: "PCS JAN", field: "jancodePcs" },
        {
          headerName: "再販情報",
          colId: "recordType",
          filterParams: { options: ProductRecordTypes.all() },
          valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
        },
        { headerName: "ASIN", field: "asin", hide: true },
        // 詳細用ダミー項目
        {
          headerName: "商品CD",
          field: "productCd",
          hide: true,
        },
        {
          headerName: "更新日",
          field: "updateDate",
          hide: true,
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          hide: true,
        },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          hide: true,
        },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
          hide: true,
        },
        { headerName: "発注単位", field: "unit", hide: true },
        { headerName: "受注単位", field: "orderUnit" },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          hide: true,
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          hide: true,
        },
        { headerName: "メーカー在庫あり", field: "stockStatus", hide: true },
        { headerName: "付属商品CD", field: "accessoriesJanCode", hide: true },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "パッケージサイズ", field: "packageSize", hide: true },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        {
          headerName: "PKGsizeW(cm)",
          field: "pkgSizeWidth",
          hide: true,
        },
        {
          headerName: "PKGsizeD(cm)",
          field: "pkgSizeDepth",
          hide: true,
        },
        {
          headerName: "PKGsizeH(cm)",
          field: "pkgSizeHeight",
          hide: true,
        },
        { headerName: "賞味期限", field: "sellBy", hide: true },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", hide: true },
        { headerName: "危険物", field: "hazardousMaterial", hide: true },
        { headerName: "原材料", field: "rawMaterials", hide: true },
        { headerName: "コピーライト", field: "copyright", width: 150 },
        { headerName: "商品説明", field: "productDetail", hide: true },
        { headerName: "仕入担当者", field: "createUser", hide: true },
        { headerName: "商品名カナ", field: "productNameKana", hide: true },
        { headerName: "メーカー備考", field: "makerRemarks", hide: true },
        { headerName: "Cafereo備考", field: "cafereoRemarks", hide: true },
      ],
      // ASIN発行依頼
      cafereoAsinColumns: [
        { headerName: "EAN", field: "jancode" },
        {
          headerName: "初回発注締め日",
          field: "firstReleaseDate",
          filter: "agDateColumnFilter",
          type: "dpEditableColumn",
          cellEditorFramework: "CustomDateCellEditor",
          valueFormatter: DateValueFormatter("YYYY/MM/DD"),
          cellStyle: { backgroundColor: "#FFEDB3" },
          editable: true,
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "購入制限数量",
          field: "maximumQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "商品カテゴリ",
          field: "categoryName",
        },
        {
          headerName: "商品サブカテゴリ",
          field: "categorySubName",
        },
        {
          headerName: "商品タイプ",
          field: "typeName",
        },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "メーカー名",
          field: "supplierName",
        },
        {
          headerName: "ブランド名",
          field: "brandName",
        },
        {
          headerName: "品番・型番",
          field: "productNumber",
        },
      ],
      cafereoNypColumns: [
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "ASIN", field: "asin" },
        { headerName: "Title", field: "title" },
        {
          headerName: "cutoff date",
          field: "cutoffDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "delivery date",
          field: "deliveryDate",
          filter: "agDateColumnFilter",
        },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          type: "dpNumericColumn",
        },
      ],
      // Cafereo商品一覧の詳細
      cafereoDetailColumns: [
        {
          headerName: "商品ステータス",
          colId: "productStatus",
        },
        {
          headerName: "登録日",
          field: "createDate",
          type: "dpFullDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          type: "dpFullDateColumn",
        },
        { headerName: "仕入担当者", field: "supplierChargeName" },
        {
          headerName: "仕入先略称",
          field: "supplierShortName",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "商品ランク", field: "productRank" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
          type: "dpDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          type: "dpFullDateColumn",
        },
        { headerName: "カテゴリー", field: "categoryName" },
        { headerName: "販路限定", field: "limitedChannels" },
        {
          headerName: "商品区分",
          colId: "productType",
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "受注単位",
          field: "orderUnit",
          type: "dpNumericColumn",
        },
        { headerName: "発注単位", field: "unit", type: "numericColumn" },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "メーカー在庫あり", field: "stockStatus", type: "dpCheckmarkColumn" },
        { headerName: "付属商品JANCODE", field: "accessoriesJanCode" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        {
          headerName: "PKGsizeW(cm)",
          field: "pkgSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "PKGsizeD(cm)",
          field: "pkgSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "PKGsizeH(cm)",
          field: "pkgSizeHeight",
          type: "dpNumericColumn",
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", type: "dpDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright", width: 150 },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          type: "dpPercentColumn",
        },
        { headerName: "商品説明", field: "productDetail" },
        { headerName: "メーカー備考", field: "makerRemarks" },
        { headerName: "Cafereo備考", field: "cafereoRemarks" },
      ],
      // Cafereo商品登録一覧
      cafereoNewProductColumns: [
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "依頼日",
          field: "createDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "仕入担当者", field: "supplierChargeName" },
        {
          headerName: "仕入先略称",
          field: "supplierShortName",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "商品ランク", field: "productRank" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "仕入価格",
          field: "purchasePrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "カテゴリー", field: "categoryName" },
        { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "発注単位", field: "unit", type: "numericColumn" },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "メーカー在庫あり", field: "stockStatus", type: "dpCheckmarkColumn" },
        { headerName: "付属商品JANCODE", field: "accessoriesJanCode" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright", width: 150 },

        { headerName: "商品説明", field: "productDetail" },
        { headerName: "メーカー備考", field: "makerRemarks" },
        { headerName: "Cafereo備考", field: "cafereoRemarks" },
      ],
      // 商品承認・却下のダイヤログ
      cafereoApprovalColumns: [
        {
          headerName: "登録日",
          field: "createDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "仕入先略称",
          field: "supplierShortName",
        },
        { headerName: "JANCODE", field: "jancode" },

        { headerName: "商品ランク", field: "productRank" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        { headerName: "受注締日", field: "cafereoClosingDate", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "カテゴリー", field: "categoryName" },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "仕入担当者", field: "supplierChargeName" },
        {
          headerName: "PCS",
          field: "pcs",
          type: "dpCheckmarkColumn",
        },
        { headerName: "PCS JAN", field: "jancodePcs" },
        {
          headerName: "再販情報",
          colId: "recordType",
          filterParams: { options: ProductRecordTypes.all() },
          valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
        },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "メーカー備考", field: "makerRemarks" },
      ],
      // 販売店商品一覧
      vendorColumns: [
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorProductStatus.all() },
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "卸掛率",
          field: "orderRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "卸価格",
          field: "unitPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "発注締日",
          field: "cafereoClosingDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "カテゴリー", field: "categoryName" },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "受注単位",
          field: "orderUnit",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "減数の可能性あり",
          field: "maximumPossibility",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "商品説明", field: "productDetail" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "再販情報",
          colId: "recordType",
          filterParams: { options: ProductRecordTypes.all() },
          valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
        { headerName: "原材料", field: "rawMaterials", width: 150 },
        { headerName: "コピーライト", field: "copyright" },
      ],
      // メーカー商品一覧
      makerColumns: [
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerProductStatus.all() },
          valueGetter: (params) => MakerProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "区分",
          colId: "recordType",
          filterParams: { options: ProductRecordTypes.all() },
          valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
        },
        { headerName: "在庫あり", field: "stockStatus", type: "dpCheckmarkColumn" },
        {
          headerName: "登録日",
          field: "createDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "販売単価",
          field: "purchasePrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "Box商品", field: "pcs", type: "dpCheckmarkColumn" },
        { headerName: "JANCODE(PSC)", field: "jancodePcs" },
        { headerName: "上代（PCS)", field: "retailPricePcs", type: "dpNumericColumn" },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "受注単位", field: "unit", type: "dpNumericColumn" },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "カテゴリー", field: "categoryName" },
        { headerName: "商品説明", field: "productDetail" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright", width: 150 },
        { headerName: "備考", field: "makerRemarks" },
      ],
      ftColumns: [],
      vendorProductDetailColumns: [
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          type: "dpFullDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          type: "dpFullDateColumn",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "卸掛率",
          field: "orderRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "卸価格",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "発注締日",
          field: "cafereoClosingDate",
          type: "dpFullDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        { headerName: "カテゴリー", field: "categoryName" },
        {
          headerName: "商品区分",
          colId: "productType",
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "受注単位",
          field: "orderUnit",
          type: "dpNumericColumn",
        },
        {
          headerName: "減数の可能性あり",
          field: "maximumPossibility",
          type: "dpCheckmarkColumn",
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "商品説明", field: "productDetail" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", type: "dpFullDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright", width: 150 },
      ],
      makerProductDetailColumns: [
        {
          headerName: "商品ステータス",
          colId: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerProductStatus.all() },
          valueGetter: (params) => MakerProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "区分",
          colId: "recordType",
          filterParams: { options: ProductRecordTypes.all() },
          valueGetter: (params) => ProductRecordTypes.of(params.data.recordType),
        },
        { headerName: "在庫あり", field: "stockStatus", type: "dpCheckmarkColumn" },
        {
          headerName: "登録日",
          field: "createDate",
          filter: "agDateColumnFilter",
          type: "dpFullDateColumn",
        },
        {
          headerName: "更新日",
          field: "updateDate",
          filter: "agDateColumnFilter",
          type: "dpFullDateColumn",
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "発注締日",
          field: "orderClosingDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
        },
        {
          headerName: "情報解禁日",
          field: "banDatetime",
          filter: "agDateColumnFilter",
          type: "dpFullDateColumn",
        },
        { headerName: "発売元", field: "salesAgencyName" },
        { headerName: "販売元", field: "salesOriginName" },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "商品名カナ", field: "productNameKana" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "販売単価",
          field: "purchasePrice",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "Box商品", field: "pcs", type: "dpCheckmarkColumn" },
        { headerName: "JANCODE(PSC)", field: "jancodePcs" },
        { headerName: "上代（PCS)", field: "retailPricePcs", type: "dpNumericColumn" },
        {
          headerName: "消費税",
          field: "consumptionTaxRate",
          type: "dpPercentColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "受注単位", field: "unit", type: "dpNumericColumn" },
        {
          headerName: "商品区分",
          colId: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        { headerName: "販路限定", field: "limitedChannels", type: "dpCheckmarkColumn" },
        {
          headerName: "上限数",
          field: "maximumQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "初回締後追加不可",
          field: "nonAdded",
          type: "dpCheckmarkColumn",
        },
        { headerName: "カテゴリー", field: "categoryName" },
        { headerName: "商品説明", field: "productDetail" },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        { headerName: "パッケージサイズ", field: "packageSize" },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
          filter: "agNumberColumnFilter",
          valueFormatter: NumberValueFormatter(),
        },
        {
          headerName: "食品",
          field: "confectionery",
          type: "dpCheckmarkColumn",
        },
        { headerName: "賞味期限", field: "sellBy", filter: "agDateColumnFilter", type: "dpDateColumn" },
        { headerName: "原産国", field: "countryOfOrigin" },
        { headerName: "飛行機検査不可", field: "airplaneInspection", type: "dpCheckmarkColumn" },
        { headerName: "危険物", field: "hazardousMaterial", type: "dpCheckmarkColumn" },
        { headerName: "原材料", field: "rawMaterials" },
        { headerName: "コピーライト", field: "copyright", width: 150 },
        { headerName: "備考", field: "makerRemarks" },
      ],
      checkNode: [],
      errorColmuns: null,
      cafereoErrorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      vendorErrorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      makerErrorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      asinErrorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      nypErrorColmuns: [
        { headerName: "Jancode", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      orderRecords: [],
    };
  },
  watch: {
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.onPageSizeChanged();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
    $route() {
      this.onSearchClick();
      this.handleResize();
    },
  },
  computed: {
    enabledProductEntry() {
      return (
        (this.allowedAction(this.constants.supplierActions) ||
          (this.isRegisteredMode && this.allowedAction(this.constants.updateActions))) &&
        this.selectionRows.length > 0
      );
    },
    isRegisteredMode() {
      return this.$route.name === "ProductRegisteredSearch";
    },
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedImageList() {
      let row = this.listSelected ? this.imgSelected : this.selectedRow;
      if (!row || !row.images) {
        return ["/samples/images/NoImage.png"];
      }
      // 画像の展開処理
      let imageList = [];
      const dateStr = moment().format("YYYYMMDDHHmmSS");
      for (let i = 0; i <= this.serviceStting.imageNumberLimit; i++) {
        const productImage = row.images.filter((v) => {
          return v.indexOf(row["productImageId" + i]) == 0;
        });
        if (productImage.length > 0) {
          // 画像のキャッシュバスティング
          let image = productImage[0].split(",")[1] + "?" + dateStr;
          imageList.push(image);
        }
      }

      console.log(imageList);
      if (!imageList || imageList.length == 0) imageList = ["/samples/images/NoImage.png"];
      return imageList;
    },

    selectedDetails() {
      let ret = null;
      let detailColumns = [];
      if (this.isCafereoUser || this.isFtransUser) {
        detailColumns = this.cafereoDetailColumns;
      } else if (this.isVendorUser) {
        detailColumns = this.vendorProductDetailColumns;
      } else if (this.isMakerUser) {
        detailColumns = this.makerProductDetailColumns;
      }
      if (this.listSelected && this.imgSelected) {
        // TODO:販売店画像一覧をやってから
        ret = getDisplayDetails2(this.imgSelected.productCd, detailColumns, this.gridApi);
      } else if (!this.listSelected && this.selectedRow) {
        ret = getDisplayDetails2(this.selectedRow.productCd, detailColumns, this.gridApi);
      } else {
        ret = [];
      }
      return ret;
    },
    selectedProductCd() {
      let row = this.listSelected ? this.imgSelected : this.selectedRow;
      if (!row) {
        return "";
      }
      return row.productCd;
    },
    productStatuses() {
      if (this.isMakerUser) {
        return MakerProductStatus.all();
      } else if (this.isVendorUser) {
        return VendorProductStatus.all();
      } else {
        return CafereoProductStatus.all();
      }
    },
    disabledAddCart() {
      // Note: vendor only.
      return (
        this.selectionRows.length === 0 ||
        this.selectionRows.some((r) => r.productStatus === 2 || r.productStatus === 5)
      );
    },
    makerUpdated() {
      return (
        this.selectionRows.length !== 0 &&
        this.isCafereoUser &&
        this.selectedRow.productStatus === BaseProductStatus.NEW
      );
    },
    getTitle() {
      return this.isMakerUser || this.isRegisteredMode ? "登録商品一覧" : "商品登録";
    },
  },
  async mounted() {
    this.$store.commit("ui/loading", true);
    try {
      if (this.allowedAction(this.constants.cafereoActions)) {
        // 編集用に法人の一覧を取得
        this.vendorCorporations = (await this.$store.dispatch("corporation/suggest"), { customerFlg: true }).records;
      }
      await this.onSearchClick();
      this.setAmazonColumnVisible();
      this.handleResize();

      // 画面解像度による画面サイズ取得
      this.gridStyle.height = this.gridHeightSize + "px";
      window.addEventListener("resize", this.handleResize);
    } catch (error) {
      console.error("mounted:", error);
      this.apiRequestError(error);
    } finally {
      this.loadingOff();
    }
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    // ***** AG-Grid APIs *****
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      let allColumnIds = [];
      let self = this;
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) {
          allColumnIds.push(column.colId);
        } else {
          if (
            self.isCafereoUser &&
            (column.colDef.field == "inBoxQuantity" ||
              column.colDef.field == "productDetail" ||
              column.colDef.field == "cafereoRemarks")
          )
            allColumnIds.push(column.colId);
        }
      });
      var params = {
        fileName: this.getTitle + ".csv",
        allColumns: false,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      };
      this.gridApi.exportDataAsCsv(params);
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
      this.rowsSelected = this.selectionRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      this.isApproval = this.rowsSelected;
      if (this.selectionRows.length > 1) {
        this.detailSelected = false;
      }
      // 画像一覧用処理
      this.imageListModels.forEach((imageListModel) => {
        imageListModel.check = false;
        for (let i = 0; i < this.selectionRows.length; i++) {
          if (imageListModel.rowData.productCd == this.selectionRows[i].productCd) {
            imageListModel.check = true;
            break;
          }
        }
      });
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);

      this.maxPage = Math.ceil(this.imageListModels.length / this.selectedPageSize);
      this.pageNumber = 1;
      this.displayModels = this.imageListModels.slice(0, this.selectedPageSize);

      this.handleResize();
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    // Gridの設定をユーザーなどによって変更
    initGridColumn() {
      this.defaultColDef = {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      this.columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: ProductClassCellRenderer({
            corporateType: this.$store.getters["security/loggedInUser"].corporateType,
          }),
        },
      ];
      if (this.isCafereoUser || this.isFtransUser) {
        if (this.isRegisteredMode) {
          this.columnDefs = this.columnDefs.concat(this.cafereoColumns);
        } else {
          this.columnDefs = this.columnDefs.concat(this.cafereoNewProductColumns);
        }
        this.columnDetailDefs = this.cafereoDetailColumns;
      }
      if (this.isVendorUser) this.columnDefs = this.columnDefs.concat(this.vendorColumns);
      if (this.isMakerUser) this.columnDefs = this.columnDefs.concat(this.makerColumns);
      // if (this.isFtransUser) this.columnDefs = this.columnDefs.concat(this.ftColumns);
    },
    setAmazonColumnVisible() {
      if (this.isCafereoUser && this.isRegisteredMode) {
        this.columnApi.setColumnsVisible(["asin"], this.isAmazonStaff());
        let columnDefs = this.columnApi.getAllColumns().map((column) => {
          let coldef = { ...column.colDef };
          if (column.colDef.field == "asin") {
            coldef.hide = false;
          }
          return coldef;
        });
        this.gridApi.setColumnDefs(columnDefs);
      }
    },
    isAmazonStaff() {
      let special = this.$store.getters["security/loggedInUser"].special;
      return special.includes(1);
    },
    // ***** 画面ボタン *****
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    onBtnInsert() {
      this.productModel = {
        createNew: true,
      };
      this.editDialog = true;
    },

    onBtnUpdate() {
      this.productModel = {
        createNew: false,
        scopeCorporateCodes: [],
        ...this.selectedRow,
      };
      this.productModel["janCodeBox"] = this.selectedRow.jancode;
      this.editDialog = true;
    },

    async onBtnDelete() {
      try {
        this.loadingOn();
        // // 選択一覧の中のトレーディング商品を取得する よくわからんから削除 3/4 石田
        // for (let i = 0; i < this.selectionRows.length; i++) {
        //   const selectRow = this.selectionRows[i];
        //   if (!deleteList.find((element) => element.rowId == selectRow.rowId)) deleteList.push(selectRow);
        //   if (selectRow.setProductCode) {
        //     const tradingProduct = this.gridApi.getRowNode(selectRow.setProductCode).data;
        //     if (!deleteList.find((element) => element.rowId == tradingProduct.rowId)) deleteList.push(tradingProduct);
        //   }
        // }
        const deleteList = {
          products: this.selectionRows.map((r) => ({
            productCd: r.productCd,
            updateDate: r.updateDate,
          })),
        };
        const messageText = `選択された商品情報を削除します <small>(${deleteList.products.length}件)</small>`;

        const ok = await this.$dialog.confirm({ title: this.getTitle, text: messageText });
        if (ok) {
          const result = await this.$store.dispatch("product/remove", deleteList);

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    jancode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "商品削除", records: errorRows });
                this.$dialog.notify.error(`商品削除処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`商品情報が削除されました (${deleteList.products.length}件)`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }

          //this.gridApi.applyTransaction({ update: deleteList });
          // this.$dialog.notify.info(`商品情報が削除されました (${deleteList.length}件)`, { timeout: 2300 });
          //this.onSelectionChanged();
          this.onSearchClick();
        }
      } catch (error) {
        console.error("ProductSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },

    onBtnApproval(approval) {
      if (this.selectedRow) {
        this.productModel = {
          approval: approval,
          scopeCorporateCodes: [],
          ...this.selectedRow,
        };
      } else {
        this.productModel = {
          approval: approval,
          scopeType: ScopeTypes.NONE,
          scopeCorporateCodes: [],
        };
      }

      this.approvalDialog = true;
    },
    onApprovalSubmit() {
      try {
        this.loadingOn();
        this.approvalDialog = false;
        this.onSearchClick();
      } catch (error) {
        console.error("ProductSearch::onApprovalSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onApprovDialogClose() {
      this.approvalDialog = false;
    },
    onDialogClose() {
      this.editDialog = false;
    },

    // ***** コンポーネント処理 *****
    async onEntrySubmit() {
      try {
        //this.loadingOn();
        this.editDialog = false;
      } catch (error) {
        console.error("ProductSearch::onEntrySubmit", error);
        //this.loadingOff();
        this.apiRequestError(error);
      }
    },

    async onEditSubmit(updateModel) {
      try {
        //this.loadingOn();
        // TODO:更新内容を一覧へ反映
        const rowData = JSON.parse(JSON.stringify(updateModel));
        this.gridApi.applyTransaction({ update: [rowData] });
        this.editDialog = false;
        this.onSearchClick();
      } catch (error) {
        console.error("ProductSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        //this.loadingOff();
      }
    },

    async onSearchClick() {
      try {
        this.loadingOn();
        console.log("linkID:", this.linkId);
        let response = null;
        if (this.linkId) {
          this.searchModel = { linkId: this.linkId };
        }
        if (this.isMakerUser) {
          response = await this.$store.dispatch("product/search", this.searchModel);
        } else if (this.isRegisteredMode) {
          this.searchModel.jancodes = this.searchModel.jancodeList
            ? this.searchModel.jancodeList
                .replaceAll(/\n+/g, " ")
                .split(" ")
                .filter((n) => n)
            : null;
          response = await this.$store.dispatch("product/postSearch", this.searchModel);
        } else {
          response = await this.$store.dispatch("product/newSearch", this.searchModel);
        }
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: this.getTitle,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.products).length === 0) {
          this.$dialog.warning({
            title: this.getTitle,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.products);
        this.rowData = result.products;
        //
        if (!this.listSelected) {
          const allColumnIds = this.columnApi
            .getAllColumns()
            .filter((column) => column.colDef.field != "copyright")
            .map((column) => column.colId);
          this.columnApi.autoSizeColumns(allColumnIds);
        }
        let treeLocked = false;
        // if (this.searchModel.productStatus || this.searchModel.createDateFrom || this.searchModel.createDateTo) {
        //   treeLocked = true;
        // }
        // 画像一覧用モデル作成
        this.imageListModels = [];
        // ツリーがロックの場合は実施する(未ロック時はツリーの選択処理を実施する)
        if (treeLocked) {
          this.rowData.forEach((row) => {
            if (row.images && Array.isArray(row.images) && row.images.length > 0) {
              var imageData = row.images[0].split(",");
              this.imageListModels.push({
                name: imageData.length > 2 ? imageData[2] : "",
                check: false,
                selected: false,
                rowData: row,
                id: row.jancode,
                url: imageData.length > 1 ? imageData[1] : "",
              });
            }
          });
          this.displayModels = this.imageListModels.slice(0, this.selectedPageSize);
          this.maxPage = Math.ceil(this.imageListModels.length / this.selectedPageSize);
        }

        // ツリー作成
        if (!this.isMakerUser) {
          await this.createTree(treeLocked);
        }
      } catch (error) {
        console.error(error);
        this.apiRequestError(error);
      } finally {
        this.selectionRows = [];
        this.loadingOff();
      }
    },

    // ***** アマゾン専用 *****
    async onBtnAsinRequest() {
      this.asinRows = [];
      try {
        this.loadingOn();
        let condition = [];
        this.selectionRows.forEach((record) => {
          condition.push({
            productCd: record.productCd,
          });
        });
        //
        const response = await this.$store.dispatch("amazon/searchIssueAsin", { products: condition });
        console.log("ProductSearch::onBtnAsinRequest", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.asinRows = response.data?.contents.asinIssueProducts;
      } catch (error) {
        console.error("ProductSearch::onBtnAsinRequest", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
      this.$refs.confirmGrid.open({
        title: "ASIN発行依頼確定",
        columns: this.cafereoAsinColumns,
        records: this.asinRows,
        onSubmit: async (records) => {
          let errorRows = [];
          var requestRecords = [];
          var isValid = true;
          var validateRowFunc = function validateRow(row) {
            var ret = true;
            var messages = [];
            // 初回発注締め日
            setValidMessageFunc(required(row.firstReleaseDate), "初回発注締め日", messages);

            if (messages.length > 0) ret = messages;
            return ret;
          };
          var setValidMessageFunc = function setValidMessage(check, culumnName, messages) {
            if (!(check === true)) messages.push(culumnName + "は" + check);
          };
          var required = function isDate(value) {
            if (value != null) {
              if (String(value).trim().length > 0) {
                return true;
              }
            }
            return "必須入力項目です";
          };

          records.forEach((record) => {
            var error = validateRowFunc(record);
            if (error != true) {
              errorRows.push({
                rowNumber: record.rowNumber,
                errorMessage: error,
              });
              isValid = false;
            } else {
              requestRecords.push(record);
            }
          });
          if (isValid) {
            try {
              this.loadingOn();
              let condition = [];
              requestRecords.forEach((record) => {
                condition.push({
                  productCd: record.productCd,
                  firstOrderDeadline: moment(record.firstReleaseDate).format("YYYY-MM-DD"),
                  lastUpdateDatetime: record.updateDateTime,
                });
              });
              const response = await this.$store.dispatch("amazon/requestAsin", { products: condition });
              console.log("ProductSearch::onSubmit", response);
              let error = response.data?.header;
              // 一覧更新レコード
              let confirmRows = [];
              let confirmSuccessRecords = [];
              let errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        jancode: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 一覧部の更新分のレコード設定
                  this.gridApi.forEachNode((node) => {
                    let row = node.data;
                    errorRows.forEach((errorRow) => {
                      if (errorRow.id != row.id) {
                        confirmSuccessRecords.push(row);
                        confirmRows.push({ id: row.id });
                      }
                    });
                  });
                  if (response.data.contents?.exportFilePath) {
                    window.open(response.data.contents.exportFilePath, "_blank");
                  }
                  // 一覧部のレコードを更新
                  // if (confirmSuccessRecords.length > 0) this.gridApi.applyTransaction({ update: confirmSuccessRecords });
                  // if (confirmRows.length > 0) this.gridOptions.applyTransaction({ remove: confirmRows });
                  // エラー表示
                  if (errorRows.length > 0) {
                    console.log("ProductSearch::onSubmit", errorRows);
                    // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                    this.$refs.errorGrid.open({ records: errorRows });
                    this.$dialog.notify.error(`ASIN発行依頼確定処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    // this.gridApi.applyTransaction({ update: this.selectionRows });
                    this.$dialog.notify.info(`選択された商品データのASIN発行依頼を出力しました`, { timeout: 2300 });
                    this.$refs.confirmGrid.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } catch (error) {
              console.error("ProductSearch::onSubmit", error);
              this.apiRequestError(error);
            } finally {
              this.loadingOff();
            }
          } else {
            this.$refs.errorGrid.open({ records: errorRows });
            this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
              timeout: 2300,
            });
          }
        },
      });
    },
    onBtnAsinImport() {
      this.asinImportDialog.show = true;
    },
    onAsinImportComplete() {
      this.asinImportDialog.show = false;
    },
    async onBtnNyp() {
      this.nypRows = [];
      try {
        this.loadingOn();
        let condition = [];
        this.selectionRows.forEach((record) => {
          condition.push({
            productCd: record.productCd,
          });
        });
        //
        const response = await this.$store.dispatch("amazon/searchResaleNyp", { products: condition });
        console.log("ProductSearch::onBtnNyp", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.nypRows = response.data?.contents.asinIssueProducts;
      } catch (error) {
        console.error("ProductSearch::onBtnNyp", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
      this.$refs.confirmGrid.open({
        title: "再販NYP確定",
        columns: this.cafereoNypColumns,
        records: this.nypRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let condition = [];
            records.forEach((record) => {
              condition.push({
                productCode: record.productCd,
                lastUpdateDatetime: record.updateDateTime,
              });
            });
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("C010104", condition.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            const response = await this.$store.dispatch("amazon/sendResaleNyp", { products: condition });
            console.log("sendResaleNyp::onSubmit", response);
            let error = response.data?.header;
            // 一覧更新レコード
            let confirmSuccessRecords = [];
            let confirmRows = [];
            let errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      jancode: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 一覧部の更新分のレコード設定
                this.gridApi.forEachNode((node) => {
                  let row = node.data;
                  errorRows.forEach((errorRow) => {
                    if (errorRow.id != row.id) {
                      confirmSuccessRecords.push(row);
                      confirmRows.push({ id: row.id });
                    }
                  });
                });
                // 一覧部のレコードを更新
                if (confirmSuccessRecords.length > 0) this.gridApi.applyTransaction({ update: confirmSuccessRecords });
                if (confirmRows.length > 0) this.gridOptions.applyTransaction({ remove: confirmRows });
                // エラー表示
                if (errorRows.length > 0) {
                  console.log("ProductSearch::onSubmit", errorRows);
                  // 確認ダイアログから更新完了分のデータを削除する(画面閉じる？)
                  this.$refs.errorGrid.open({ records: errorRows });
                  this.$dialog.notify.error(`再販NYP確定処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  // this.gridApi.applyTransaction({ update: this.selectionRows });
                  var exportFilePath = response.data?.contents.exportFilePath;
                  if (exportFilePath != "" && exportFilePath != null) {
                    window.open(exportFilePath, "_blank");
                  }
                  this.$dialog.notify.info(`選択された商品データの再販NYPを出力しました`, { timeout: 2300 });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("ProductSearch::onSubmit", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete() {
      this.importDialog.show = false;
    },

    // ***** 商品登録フォーマット *****
    async onBtnDownload4Vendor() {
      this.outProductList("product/outList4Vendor", false);
    },
    async onBtnDownloadEc4Vendor() {
      this.outProductList("product/outList4Vendor", true);
    },
    async onBtnDownloadAnimate() {
      this.outProductList("product/outListAnimate");
    },
    async onBtnDownloadYodobashi() {
      this.outProductList("product/outListYodobashi");
    },
    async onBtnDownloadYodobashiEc() {
      this.outProductList("product/outListYodobashiEc");
    },
    async onBtnDownloadSofmap() {
      this.outProductList("product/outListSofmap");
    },
    async onBtnDownloadVVG() {
      this.outProductList("product/outListVillagev");
    },
    async onBtnDownloadHands() {
      this.outProductList("product/outListHands");
    },
    async onBtnDownloadLoft() {
      this.outProductList("product/outListLoft");
    },
    async onBtnDownloadLoftEc() {
      this.outProductList("product/outListLoftEc");
    },
    async outProductList(storeName, ecFlg) {
      try {
        this.loadingOn();
        let jancodes = [];
        this.selectionRows.forEach((row) => {
          jancodes.push(row.jancode);
        });
        let condition = { products: jancodes };
        if (this.isVendorUser) {
          condition.ecFlg = ecFlg;
        }
        const response = await this.$store.dispatch(storeName, condition);
        console.log(response.data);
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            window.open(response.data.contents.exportFilePath, "_blank");
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            var message = "";
            Object.keys(response.data?.header.messages).forEach(function (key) {
              message += response.data?.header.messages[key].join("<br>");
            });
            if (message != "") {
              this.$dialog.warning({
                title: `商品登録フォーマット`,
                text: message,
                actions: ["OK"],
              });
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("ProductEntry::" + storeName, error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },

    // ***** メーカー在庫更新 *****
    onBtnSwitchSock() {
      this.makerStockDialog.show = true;
    },
    onMakerStockDialogClose(updateRecords) {
      this.makerStockDialog.show = false;
      if (updateRecords.length > 0) this.onSearchClick();
    },
    async onMakerStockDialogSubmit() {
      this.makerStockDialog.show = false;
      this.onSearchClick();
    },

    // ***** 商品リンク *****
    async onBtnLink() {
      try {
        this.loadingOn();
        // 選択一覧の中の商品コードを取得する
        let productCodeList = [];
        for (let i = 0; i < this.selectionRows.length; i++) {
          productCodeList.push(this.selectionRows[i].productCd);
        }
        let response = await this.$store.dispatch("product/getLink", { productCode: productCodeList });
        console.log(response);
        this.productLink = response.data.contents.productLinkUrl;
        this.linkDialog = true;
      } catch (error) {
        console.error("ProductSearch::onBtnLink", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onLinkDialogClose() {
      this.linkDialog = false;
    },

    // ***** 法人別掛率設定 *****
    async onBtnPercent() {
      this.percentDialog.show = true;
    },
    onPercentDialogClose() {
      this.percentDialog.show = false;
    },

    // ***** 注文表 *****
    onBtnOpenCart() {
      this.cartDialog.show = true;
    },
    onCartDialogClose(orderRecords) {
      this.cartDialog.show = false;
      this.initialCart = [];
      this.orderRecords = orderRecords;
    },
    async onCartEntrySubmit() {
      this.cartDialog.show = false;
      this.initialCart = [];
      this.orderRecords = [];
    },
    onBtnInportCart() {
      // Note: カートを一度も表示したことがない場合はコンポーネントが存在しない
      if (this.$refs.productCart) {
        this.$refs.productCart.addCart(this.selectionRows);
      } else {
        this.initialCart.push(...this.selectionRows);
      }
      this.$dialog.notify.info(`注文票に追加しました`, { timeout: 2300 });
    },

    // ***** 画像一覧 *****
    changePage() {
      this.displayModels = this.imageListModels.slice(
        this.selectedPageSize * (this.pageNumber - 1),
        this.selectedPageSize * this.pageNumber
      );
    },
    onCheck(event) {
      // グリッドのチェックに反映
      this.gridApi.forEachNode(function (node) {
        if (node.data.productCd == event.inputModel.rowData.productCd) {
          node.setSelected(event.check);
        }
      });
    },
    onSelectedImg(event) {
      this.imageListModels.forEach((inputModel) => {
        inputModel.selected = false;
        if (event.inputModel.id == inputModel.id) inputModel.selected = true;
      });
      this.$refs.imgCard.forEach((component) => {
        component.onSelectedRefresh();
      });
      this.imgSelected = event.inputModel.rowData;
      this.imgSelectedValue = getProductDisplayDetailValues2(
        event.inputModel.rowData.productCd,
        this.vendorProductDetailColumns,
        this.gridApi
      );

      this.detailDialog = true;
    },
    onBtnAllCheck() {
      this.displayModels = [];
      this.imageListModels.forEach((inputModel) => {
        inputModel.check = true;
        this.gridApi.forEachNode(function (node) {
          if (node.data.productCd == inputModel.rowData.productCd) {
            node.setSelected(true);
          }
        });
      });
      this.displayModels = this.imageListModels.slice(0, this.selectedPageSize);
    },
    onBtnAllUncheck() {
      this.displayModels = [];
      this.imageListModels.forEach((inputModel) => {
        inputModel.check = false;
        this.gridApi.forEachNode(function (node) {
          if (node.data.productCd == inputModel.rowData.productCd) {
            node.setSelected(false);
          }
        });
      });
      this.displayModels = this.imageListModels.slice(0, this.selectedPageSize);
    },
    async onBtnImageExport() {
      try {
        this.loadingOn("画像ファイルを圧縮中です");
        var products = [];
        this.selectionRows.forEach((row) => {
          if (row.images && Array.isArray(row.images) && row.images.length > 0) {
            row.images.forEach((image) => {
              var url = image.split(",");
              if (url.length > 2 && url[2] != "") {
                products.push({ logicalFileName: url[2] });
              }
            });
          }
        });
        const response = await this.$store.dispatch("productImage/download", { products: products });
        if (response.data?.header.resultCode == ApiStatus.consts.BUSINESS_ERROR) {
          this.$dialog.warning({
            title: "商品画像ダウンロード",
            text: response.data?.header.messages[0][0],
            actions: ["OK"],
          });
        } else {
          const exportUrl = response.data.contents.exportFilePath;
          FileUtils.downloadFile(exportUrl);
        }
      } catch (error) {
        console.error("ProductSearch::onBtnImageExport", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDetailDialogClose() {
      this.detailDialog = false;
    },
    onCartAddSubmit(productModel) {
      // Note: カートを一度も表示したことがない場合はコンポーネントが存在しない
      if (this.$refs.productCart) {
        this.$refs.productCart.addCart([productModel]);
      } else {
        this.initialCart.push(productModel);
      }
      this.$dialog.notify.info(`注文票に追加しました`, { timeout: 2300 });
      this.detailDialog = false;
    },
    // カテゴリツリー作成
    async createTree(treeLocked) {
      const response = await this.$store.dispatch("productImage/getProductImageTree", {});
      if (ApiStatus.isSystemError(response.data?.header)) {
        return this.redirectError();
      }
      let old = null;
      this.treeItems = [];
      // let treeItem = {
      //   id: 1,
      //   name: "すべてのカテゴリ",
      //   children: [],
      // };
      let supplierItems = {};
      response.data.contents.imageTree.forEach((elm) => {
        // 初回は初期化
        if (!old) {
          supplierItems = { id: elm.supplierCd, name: elm.supplierName, children: [], locked: treeLocked };
        }
        // キーが変わったときに仕入先ノードの処理
        if (old && elm.supplierCd != old.supplierCd) {
          //treeItem.children.push(supplierItems);
          this.treeItems.push(supplierItems);
          supplierItems = { id: elm.supplierCd, name: elm.supplierName, children: [], locked: treeLocked };
        }
        //
        old = elm;
      });
      // treeItem.children.push(supplierItems);
      // this.treeItems.push(treeItem);
      this.treeItems.push(supplierItems);
      this.treeOpen = [1];
      if (!treeLocked) await this.onclickTreeNode(this.checkNode);
    },
    // ツリーのチェック状態変更
    async onclickTreeNode(node) {
      try {
        this.loadingOn();
        this.imageListModels = [];
        console.log(node);
        for (const elm of node) {
          // 画像カードの作成
          this.searchModel.supplierCd = elm;
          this.searchModel.categoryId = null;
          this.searchModel.isNullCategory = false;
          const response = await this.$store.dispatch("productImage/search", { searchCondition: this.searchModel });
          console.debug("productImage/search:", response);
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          response.data.contents.images.forEach((image) => {
            let isSomeProduct = this.imageListModels.some((r) => r.id == image.jancode);
            if (!isSomeProduct) {
              let rowData = this.rowData.find((row) => row.jancode == image.jancode);
              if (rowData && rowData.images && Array.isArray(rowData.images) && rowData.images.length > 0) {
                let imageData = rowData.images[0].split(",");
                this.imageListModels.push({
                  name: imageData.length > 2 ? imageData[2] : "",
                  check: false,
                  selected: false,
                  rowData: rowData,
                  id: rowData.jancode,
                  url: imageData.length > 1 ? imageData[1] : "",
                });
              }
            }
          });
        }
        this.displayModels = this.imageListModels.slice(0, this.selectedPageSize);
        this.maxPage = Math.ceil(this.imageListModels.length / this.selectedPageSize);
        this.onPageSizeChanged();
        // 検索したときのため保存
        this.checkNode = node;
      } catch (error) {
        console.error("ProductSearch::onclickTreeNode", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    dateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.createDateTo))
        return "登録日付Toより前の日付を指定してください";
      return true;
    },
    dateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.createDateFrom))
        return "登録日付Fromより後の日付を指定してください";
      return true;
    },
    banDateFromRules(value) {
      if (value == null || this.searchModel.banDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.banDateTo))
        return "情報解禁日Toより前の日付を指定してください";
      return true;
    },
    banDateToRules(value) {
      if (value == null || this.searchModel.banDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.banDateFrom))
        return "情報解禁日Fromより後の日付を指定してください";
      return true;
    },
    onBtnBulkEdit() {
      this.orderBulkEditModel = { ...this.selectedRow };
      this.editBulkDialog = true;
    },
    onBulkEdit(searchFlg) {
      this.editBulkDialog = false;
      if (searchFlg) this.onSearchClick();
    },
  },

  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.initGridColumn();
    this.rowData = [];
    if (this.isCafereoUser) this.errorColmuns = this.cafereoErrorColmuns;
    if (this.isVendorUser) this.errorColmuns = this.vendorErrorColmuns;
    if (this.isMakerUser) this.errorColmuns = this.makerErrorColmuns;

    if (!this.isRegisteredMode) {
      console.log("aaaaa:", this.$store.getters["security/loggedInUser"].loginId);
      this.defaultCustomerStaff = this.$store.getters["security/loggedInUser"].userId;
    }

    this.searchModel.createDateFrom = this.isCafereoUser ? moment().add(-1, "months").format("YYYY-MM-DD") : null;
    this.searchModel.banDateFrom = this.isVendorUser ? moment().add(-1, "months").format("YYYY-MM-DD") : null;
  },
};
</script>
