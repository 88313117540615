<template>
  <v-card :elevation="elevation" @click="onClick">
    <v-img :aspect-ratio="1" max-height="250" :src="`${inputModel.url}?${new Date().getTime()}`" :contain="true">
    </v-img>
    <v-checkbox
      style="word-break: break-all"
      :label="inputModel.name"
      v-model="inputModel.check"
      @click.stop="$emit('onCheck', { check: inputModel.check, inputModel: inputModel })"
    ></v-checkbox>
  </v-card>
</template>

<script>
export default {
  name: "ProductImageCard",
  model: { prop: "active", event: "push" },
  props: {
    inputModel: {
      check: { type: Boolean },
      image: { type: String },
      name: { type: String },
      selected: { type: Boolean },
      id: { type: Number },
      url: { type: String },
      rowData: { type: String },
    },
    isImgClickCheck: { type: Boolean },
    isClickSelected: { type: Boolean },
  },

  data: () => ({
    checkModel: false,
    elevation: 2,
  }),
  methods: {
    onClick() {
      console.log(this.isImgClickCheck);
      if (this.isImgClickCheck) {
        this.inputModel.check = !this.inputModel.check;
        this.$emit("onCheck", { check: this.inputModel.check, inputModel: this.inputModel });
      }
      if (this.isClickSelected) {
        this.$emit("onSelected", { inputModel: this.inputModel });
      }
    },
    onCheckOn() {
      this.inputModel.check = true;
      this.$forceUpdate();
    },
    onCheckOff() {
      this.inputModel.check = false;
      this.$forceUpdate();
    },
    onSelectedRefresh() {
      if (this.inputModel.selected) {
        this.elevation = 24;
      } else {
        this.elevation = 2;
      }
    },
  },
};
</script>
